import React from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { useParams, Link, useHistory } from "react-router-dom";
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from "recharts";
import LockIcon from "@material-ui/icons/Lock";
import "../../style/DealPreview.css";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import NDAOpener from "./NDAOpener";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DateRangeIcon from "@material-ui/icons/DateRange";
import GetAppIcon from "@material-ui/icons/GetApp";
import { extractFileType } from "./Upload";
import { revenue_ranges, ebitda_ranges } from "./Helpers/FinancialRanges";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { getDealsById } from "../../API";

const numberWithCommas = (x) => {
  return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const DealPreview = (props) => {
  let history = useHistory();

  const [findersDoc, setFindersDoc] = React.useState(false);

  const [dealInfo, setDealInfo] = React.useState(null);

  const [graphData, setGraphData] = React.useState(null);

  let { dealid } = useParams();

  React.useEffect(() => {
    console.log("got hree", dealid);
    getDealsById([dealid])
      .then((res) => {
        setDealInfo(res.data[0]);
        console.log(res.data[0]);
        let info = [];
        info.push({
          year: 2018,
          ebitda: res.data[0]["ebitda_2018"],
          revenue: res.data[0]["revenue_2018"],
        });
        info.push({
          year: 2019,
          ebitda: res.data[0]["ebitda_2019"],
          revenue: res.data[0]["revenue_2019"],
        });
        info.push({
          year: 2020,
          ebitda: res.data[0]["ebitda_2020"],
          revenue: res.data[0]["revenue_2020"],
        });
        setGraphData(info);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dealid]);

  const fileNames = () => (
    <Grid container spacing={2}>
      {dealInfo["originalName"].map((item) => (
        <Grid item xs style={{ padding: 10 }}>
          <FontAwesomeIcon icon={faFile} size="30px" /> {"\n"}
          {item}
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      {dealInfo && (
        <>
          <Grid container>
            <Grid item xs={1}>
              <IconButton onClick={() => history.goBack()}>
                <FontAwesomeIcon icon={faArrowAltCircleLeft} />
              </IconButton>
            </Grid>

            <Grid item xs={10} align="center" style={{ fontSize: 40 }}>
              Project {dealInfo["alias"]}
              {/* {dealInfo.saved ? <StarIcon style={{fontSize: 35, marginLeft: 10, cursor: 'pointer'}} color='primary' onClick={() => {props.removeSaved(dealInfo['_id'], props.preview)}}/> : <StarBorderIcon color="primary" style={{fontSize: 35, marginLeft: 10, cursor: 'pointer'}} onClick={() => {props.addSaved(dealInfo['_id'], props.preview)}} /> } */}
            </Grid>
            <Grid item xs={1} align="right">
              <Button
                onClick={() => {
                  setFindersDoc(true);
                }}
                style={{ textTransform: "none" }}
                variant="outlined"
              >
                Next step
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={5} style={{ textAlign: "left" }}>
              <Card align="center" style={{ padding: 8 }}>
                <CardContent>
                  <h2 align="center">Basic Company Information</h2>
                  <div style={{ textAlign: "left" }}>
                    <ul style={{ listStylePosition: "inside", fontSize: 18 }}>
                      <li>
                        <strong>Description:</strong>{" "}
                        {dealInfo["productDescription"]}
                      </li>
                      <li>
                        <strong>Industry:</strong> {dealInfo["industry"]}
                      </li>
                      <li>
                        <strong> State:</strong> {dealInfo["state"]}
                      </li>
                      <li>
                        <strong>EBITDA Range:</strong>{" "}
                        {ebitda_ranges(dealInfo["ebitda_2020"])}
                      </li>
                      <li>
                        <strong>Revenue Range:</strong>{" "}
                        {revenue_ranges(dealInfo["revenue_2020"])}
                      </li>
                    </ul>
                  </div>
                </CardContent>
              </Card>
              <Card style={{ padding: 8, marginTop: 12 }}>
                <h2 align="center">Files Available to Download</h2>
                <CardContent align={dealInfo.downloaded ? "" : "center"}>
                  There is a total of {dealInfo["uuidName"].length} file(s),
                  covering the following diligence expectations: <br />
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <CheckBoxIcon style={{ color: "green" }} />
                      </ListItemIcon>
                      <ListItemText>
                        Full financial statements between 2018 and 2020
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckBoxIcon style={{ color: "green" }} />
                      </ListItemIcon>
                      <ListItemText>
                        Full industry analysis including 12 comparable firms in
                        the industry
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckBoxIcon style={{ color: "green" }} />
                      </ListItemIcon>
                      <ListItemText>In-depth leadership analysis</ListItemText>
                    </ListItem>
                  </List>
                  {/* {dealInfo['uuidName'] &&
                    <Grid container>
                      {dealInfo['uuidName'].map(thing =>
                        <Grid item xs={6}>
                          <Tooltip title="Files are locked until deal is signed">
                          <FontAwesomeIcon style={{fontSize: 20}} icon={extractFileType(thing)[0]}/>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid> */}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={7} align="center">
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Card>
                    <CardContent>
                      <Tooltip title="Number of Views" placement="top">
                        <VisibilityIcon />
                      </Tooltip>
                      <br /> 13
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card>
                    <CardContent>
                      <Tooltip title="Number of Downloads" placement="top">
                        <GetAppIcon />
                      </Tooltip>
                      <br /> 4
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card>
                    <CardContent>
                      <Tooltip
                        title="Date deal went live on Velladore"
                        placement="top"
                      >
                        <DateRangeIcon />
                      </Tooltip>
                      <br /> 12/24/2020
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Card style={{ padding: 1 }}>
                <p align="center">EBITDA</p>
                <LineChart
                  width={450}
                  height={250}
                  data={graphData}
                  margin={{ right: 20, left: 15 }}
                >
                  <Line type="monotone" dataKey="ebitda" stroke="#8884d8" />
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  <XAxis dataKey="year" />
                  {/* <YAxis /> */}
                </LineChart>
                <p align="center" style={{ marginTop: 40 }}>
                  Revenue
                </p>
                <LineChart
                  width={450}
                  height={250}
                  data={graphData}
                  margin={{ right: 20, left: 15 }}
                >
                  <Line type="monotone" dataKey="revenue" stroke="#8884d8" />
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  <XAxis dataKey="year" />
                  {/* <YAxis /> */}
                </LineChart>
              </Card>
            </Grid>
          </Grid>
          <div align="center"></div>
          <NDAOpener
            user={props.user}
            dealid={dealInfo["_id"]}
            findersDoc={findersDoc}
            setFindersDoc={setFindersDoc}
          />
        </>
      )}
    </>
  );
};

export default DealPreview;
