import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';

function getSteps() {
    return ['Upload Documents', 'Verified', 'Rate accepted', 'Live on Velladore'];
  }

const UploadStatus = (props) => {

  React.useEffect(() => {
    switch(props.status) {
      case 'uploaded':
        setActiveStep(0);
        break;
      case 'verified':
        setActiveStep(1);
        break;
      case 'accepted':
        setActiveStep(2);
        break;
      default:
        setActiveStep(3);
        break;
    }
  }, [props.status])

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    return (
        <Stepper alternativeLabel activeStep={activeStep} style={{paddingLeft: 0, paddingTop: 0, paddingBottom: 0}}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    )
}

export default UploadStatus;
