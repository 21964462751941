import FilterListIcon from "@material-ui/icons/FilterList";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import Slider from "@material-ui/core/Slider";
import {
  industry_array,
  location_array,
  revenue_marks,
  ebitda_marks,
} from "../../Models/Industry";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import '../../style/Download.css'
import { Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Toolbar = (props) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [revenue, setRevenue] = React.useState([props.search_terms.revenueMin, props.search_terms.revenueMax]);
  const [ebitda, setEbitda] = React.useState([props.search_terms.ebitdaMin, props.search_terms.ebitdaMax]);
  const [industry, setIndustry] = React.useState(props.search_terms.industry);
  const [location, setLocation] = React.useState(props.search_terms.location);
  const [favorited, setFavorited] = React.useState(false)

  const handleRevenue = (event, newValue) => {
    setRevenue(newValue);
  };

  const handleEbitda = (event, newValue) => {
    setEbitda(newValue);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleIndustry = (event) => {
    setIndustry(event.target.value);
  };

  const handleLocation = (event) => {
    setLocation(event.target.value);
  };

  const handleFavorited = () => {
    setFavorited(!favorited);
  }

  function valuetext(value) {
    return `$${value}`;
  }

  console.log(props);

  return (
    <>
      <Tooltip title="Filter list" onClick={handleOpen}>
        <IconButton aria-label="filter list">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => [setOpen(false)]}
        maxWidth="lg"
        fullWidth={true}
      >
        {/* <DialogTitle align="center"> <Typography style={{fontSize: 35, fontWeight: 'bold'}}>Filter Deals </Typography></DialogTitle> */}
        <DialogContent>
          Favorited Deals Only
          <Switch
        checked={favorited}
        onChange={handleFavorited}
        color="primary"
        name="checkedB"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />

          <h2 style={{fontWeight: 'normal'}}>Revenue</h2>
          <Slider
            style={{ marginTop: 50 }}
            value={revenue}
            onChange={handleRevenue}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            valueLabelDisplay="off"
            valueLabelFormat={valuetext}
            marks={revenue_marks}
            step={null}
            max={500}
            min={0}
          />
          <br />
          <h2 style={{fontWeight: 'normal'}}>EBITDA</h2>
          <Slider
            style={{ marginTop: 50 }}
            value={ebitda}
            onChange={handleEbitda}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            valueLabelDisplay="off"
            valueLabelFormat={valuetext}
            marks={ebitda_marks}
            step={null}
            max={500}
            min={0}
          />
          <h2 style={{fontWeight: 'normal'}}>Industry</h2>
          <Select
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            value={industry}
            onChange={handleIndustry}
            // input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
            style={{width: '100%'}}
          >
            {industry_array.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <br />
          <h2 style={{fontWeight: 'normal'}}>Location</h2>
          <Select
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            value={location}
            onChange={handleLocation}
            // input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
            style={{width: '100%'}}
          >
            {location_array.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions >
          <div style={{marginLeft: '43%', marginRight: '43%', display: 'flex'}}>
            <Link to={`/explore?rmin=${revenue[0]}&rmax=${revenue[1]}&emin=${ebitda[0]}&emax=${ebitda[1]}&favorited=${favorited}`}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {handleOpen()}}
            style={{marginRight: '5px', backgroundColor: '#1E1E44', textTransform: 'none'}}
          >
            Search
          </Button>
          </Link>
          <Button
            variant="contained"
            onClick={() => {setIndustry([]); setLocation([]); setRevenue([0, 500]); setEbitda([0, 500]);}}
            style={{color: 'gray', marginLeft: '5px', textTransform: 'none'}}
          >
            Reset
          </Button>
          </div>

        </DialogActions>
      </Dialog>
    </>
  );
};

export default Toolbar;
