const revenue_ranges = (dollar_amount) => {
    if (dollar_amount >= 250000000) {
        return '200MM-300MM';
    }
    else if (dollar_amount >= 200000000) {
        return '150MM-250MM';
    }
    else if (dollar_amount >= 150000000) {
        return '100MM-200MM';
    }
    else if (dollar_amount >= 100000000) {
        return '50MM-150MM';
    }
    else {
        return '0-50MM';
    }
}

const ebitda_ranges = (dollar_amount) => {
        if (dollar_amount >= 100000000) {
            return '120MM-200MM';
        }
        else if (dollar_amount >= 90000000) {
            return '90MM-120MM';
        }
        else if (dollar_amount >= 60000000) {
            return '60MM-90MM';
        }
        else if (dollar_amount >= 30000000) {
            return '30MM-60MM';
        }
        else {
            return '0-30MM';
        }
}

export { revenue_ranges, ebitda_ranges };
