import React from "react";
import Modal from "react-modal-resizable-draggable";
import "../../style/DraggableModal.css";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Card,
  Grid,
  CardContent,
  IconButton,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from "recharts";
import { extractFileType } from "./Upload";
import { downloadFile } from "../../API";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import EditableLabel from "./Helpers/EditableLabel";
import { getNotes, editNote, deleteNote } from "../../API";
import DealMap from "./DealMap";
import {
  faFile,
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faLock,
  faAddressCard,
  faUserCircle,
  faMapPin,
  faPaperPlane
} from "@fortawesome/free-solid-svg-icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const numberWithCommas = (x) => {
    return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

const ModalDragger = (props) => {
  const [notes, setNotes] = React.useState(null);
  const [modalIsOpen, setModalIsOpen] = React.useState(true);

  React.useEffect(() => {
    if (props.deals === null) {
      return;
    }
    console.log("loading info from API");
    getNotes(props.deals[props.index]["_id"])
      .then((res) => {
        console.log(res);
        if (res.data[0] === undefined) {
          setNotes([]);
        }
        setNotes(res.data[0].notes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props]);

  const _handleFocus = (text, index) => {
    console.log(index);
    console.log("Focused with text: " + text);
  };

  const _handleFocusOut = (text, index) => {
    console.log(index);
    let newNotes = [...notes];
    newNotes[index] = text;
    setNotes(newNotes);
    fireNoteUpdate(newNotes, props.deals[props.index]["_id"]);
  };

  const fireNoteUpdate = (updates, dealid) => {
    editNote(updates, dealid);
    console.log(updates);
  };

  const newNote = () => {
    console.log("editing note");
    let newNotes = [...notes, "Click to edit"];
    setNotes(newNotes);
  };

  const handleDelete = (index) => {
    console.log(index);
    let current = [...notes];
    current.splice(index, 1);
    // let current = [...notes];
    // console.log('now:', current);
    // current.splice(index, 1);
    // console.log('spliced', current);
    setNotes(current);
    fireDeleteNote(current, props.deals[props.index]["_id"]);
  };

  const fireDeleteNote = (changes, dealid) => {
    editNote(changes, dealid);
  };

  const downloadDocument = (filer) => {
    downloadFile(filer)
      .then((res) => {
        window.open(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <div className="App">
      <Modal
        initWidth={800}
        initHeight={500}
        // className={"my-modal-custom-class"}
        onRequestClose={closeModal}
        isOpen={modalIsOpen}
      >
        {props.deals && (
          <Grid container spacing={1} style={{ marginTop: 15 }}>
            <Grid item xs={1}>
              <IconButton onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={11} style={{ marginTop: 0 }} />
            <Grid item xs={12} sm={5} md={5}>
              <Card>
                <h2 align="center">Downloadable Files</h2>
                <Grid container style={{ marginBottom: 10 }}>
                  {props.deals[props.index]["originalName"].map(
                    (document, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        align="center"
                        style={{ marginTop: "10px" }}
                      >
                        {
                          <FontAwesomeIcon
                            style={{
                              fontSize: 25,
                              cursor: "pointer",
                              color: extractFileType(
                                props.deals[props.index]["originalName"][index]
                              )[1],
                            }}
                            onClick={() =>
                              downloadDocument(
                                props.deals[props.index]["uuidName"][index]
                              )
                            }
                            icon={
                              extractFileType(
                                props.deals[props.index]["originalName"][index]
                              )[0]
                            }
                          />
                        }
                        <br />
                        {props.deals[props.index]["originalName"][index]}
                      </Grid>
                    )
                  )}
                </Grid>
              </Card>
              <Card style={{ marginTop: 20, marginBottom: 20 }}>
                <h2 align="center">Notes</h2>
                <ul>
                  {notes &&
                    notes.map((item, index) => {
                      console.log(item, index);
                      return (
                        <li style={{ display: "flex" }}>
                          <EditableLabel
                            text={item}
                            inputWidth="200px"
                            inputHeight="25px"
                            inputMaxLength={50}
                            labelFontWeight="bold"
                            inputFontWeight="bold"
                            emptyEdit={true}
                            onFocus={() => {
                              _handleFocus(item, index);
                            }}
                            onFocusOut={(e) => {
                              _handleFocusOut(e, index);
                            }}
                          />
                          <CloseIcon
                            onClick={() => {
                              handleDelete(index);
                            }}
                            style={{
                              fontSize: 20,
                              color: "red",
                              cursor: "pointer",
                            }}
                          />
                        </li>
                      );
                    })}
                </ul>
                <IconButton onClick={newNote}>
                  <AddIcon />
                </IconButton>
              </Card>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Card style={{ marginBottom: 10 }}>
                <CardContent>
                  <a
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    href={`//${props.deals[props.index]["url"]}`}
                  >
                    {props.deals[props.index]["url"]}
                  </a>
                  {/* <iframe style={{width: '100%', minHeight: 500}} src="http://www.snlpartners.com/" title="company site"/> */}
                </CardContent>
              </Card>
              <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      Contact Card
                  </AccordionSummary>
                  <AccordionDetails>
                      <div style={{display: 'grid'}}>
                      <div>
                           <FontAwesomeIcon icon={faUserCircle}/> {props.deals[props.index]["contactName"]}
                      </div>
                      <div>
                      <FontAwesomeIcon icon={faPaperPlane}/> {props.deals[props.index]["contactEmail"]}
                      </div>
                      <div>
                      <FontAwesomeIcon icon={faMapPin}/> {props.deals[props.index]["address"]}
                      </div>   
                      </div>

                  </AccordionDetails>
              </Accordion>
              <Card style={{ marginBottom: 10 }}>
                {/* {`${props.firmCity} ${props.coordinates['lat']} ${props.coordinates['long']}`} */}
                <DealMap
                  companyCity={props.deals[props.index]["city"]}
                  companyCoordinates={
                    props.deals[props.index].companyCoordinates
                  }
                  firmCity={props.firmCity}
                  firmCoordinates={props.coordinates}
                />
              </Card>
            </Grid>
            <Grid item xs={6}>
            <Card align="center">
                <p align="center">EBITDA</p>
                <LineChart
                  width={350}
                  height={200}
                  data={[
                    {
                      year: 2018,
                      ebitda: props.deals[props.index]["ebitda_2018"],
                    },
                    {
                      year: 2019,
                      ebitda: props.deals[props.index]["ebitda_2019"],
                    },
                    {
                      year: 2020,
                      ebitda: props.deals[props.index]["ebitda_2020"],
                    },
                  ]}
                  margin={{ right: 20, left: 15 }}
                >
                  <Line type="monotone" dataKey="ebitda" stroke="#8884d8" />
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  <XAxis dataKey="year" />
                  <YAxis />
                </LineChart>
              </Card>
              <Card style={{marginTop: 8}}>
                  <CardContent>
                  <div style={{display: 'flex'}}>
                          2018 EBITDA: {numberWithCommas(props.deals[props.index]["ebitda_2018"])}
                      </div>
                      <div style={{display: 'flex'}}>
                          2019 EBITDA: {numberWithCommas(props.deals[props.index]["ebitda_2019"])}
                      </div>
                      <div style={{display: 'flex'}}>
                          2020 EBITDA: {numberWithCommas(props.deals[props.index]["ebitda_2020"])}
                      </div>
                  </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card align="center">
                <div>
                  <p align="center">Revenue</p>
                  <LineChart
                    width={350}
                    height={200}
                    data={[
                      {
                        year: 2018,
                        revenue: props.deals[props.index]["revenue_2018"],
                      },
                      {
                        year: 2019,
                        revenue: props.deals[props.index]["revenue_2019"],
                      },
                      {
                        year: 2020,
                        revenue: props.deals[props.index]["revenue_2020"],
                      },
                    ]}
                    margin={{ right: 20, left: 15 }}
                  >
                    <Line type="monotone" dataKey="revenue" stroke="#8884d8" />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="year" />
                    <YAxis />
                  </LineChart>
                </div>
              </Card>
              <Card style={{marginTop: 8}}>
                  <CardContent>
                  <div style={{display: 'flex'}}>
                          2018 Revenue: {numberWithCommas(props.deals[props.index]["revenue_2018"])}
                      </div>
                      <div style={{display: 'flex'}}>
                          2019 Revenue: {numberWithCommas(props.deals[props.index]["revenue_2019"])}
                      </div>
                      <div style={{display: 'flex'}}>
                          2020 Revenue: {numberWithCommas(props.deals[props.index]["revenue_2020"])}
                      </div>
                  </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Modal>
    </div>
  );
};

export default ModalDragger;
