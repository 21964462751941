import React from 'react';
import './Sidebar.css';
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import logo from '../../static_pages/navy_logo.png';
import { useHistory } from "react-router-dom";
import ReactDOM from 'react-dom';

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

const hidebg = () => {
    let element = document.getElementById('landing-text');
    ReactDOM.findDOMNode(element).style.display = 'none';
}

const Sidebar = (props) => {

    const history = useHistory();

    const [contactOpen, setContactOpen] = React.useState(false);
    const [loginOpen, setLoginOpen] = React.useState(false);
    const [registerOpen, setRegisterOpen] = React.useState(false);
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [name, setName] = React.useState('');
    const [firm, setFirm] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [email, setEmail] = React.useState('');

    const dummyAuth = (event) => {
        event.preventDefault();
        if (username === "test@gmail.com" && password === "password") {
          history.push("/user");
        }
      };

    const handleContactClose = () => {
        setContactOpen(false);
        let element = document.getElementById('landing-text');
        ReactDOM.findDOMNode(element).style.display = 'flex';
      };
    
    const handleLoginClose = () => {
    setLoginOpen(false);
    let element = document.getElementById('landing-text');
    ReactDOM.findDOMNode(element).style.display = 'flex';
    };
    const handleRegisterClose = () => {
        setRegisterOpen(false);
        let element = document.getElementById('landing-text');
        ReactDOM.findDOMNode(element).style.display = 'flex';
    };

      const handleSubmission = e => {
        console.log(name, firm, email);
        fetch("/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: encode({
                    "form-name": "contact",
                    name: name,
                    email: email,
                    firm: firm,
                    message: message
                })
            })
            .then(() => {
                handleContactClose();
                console.log("close contact");
            })
            .catch((error) => {
                console.log(error);
            });
        e.preventDefault();
    };

    const handleRegisterSubmission = e => {
        console.log(name, firm, email);
        fetch("/", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: encode({
                "form-name": "register",
                name: name,
                email: email,
                firm: firm,
            })
        })
            .then(() => {
                handleRegisterClose();
                console.log("close register");
            })
            .catch((error) => {
                console.log(error);
            });
        e.preventDefault();
    };
    return (
        <>
        {props.open && 
            <>
        <Dialog open={contactOpen} onClose={handleContactClose} maxWidth="sm" fullWidth={true} align="center">
            <DialogTitle>
            <img src={logo} align="center" style={{width: 40}} alt="logo"/>
            <Typography style={{fontSize: 25}}> Contact Us</Typography>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmission}>
                <TextField label="Name" fullWidth={true} onChange={event => setName(event.target.value)}/>
                <TextField label="Email address" type="email" fullWidth={true} onChange={event => setEmail(event.target.value)}/>
                <TextField label="Firm" type="text" fullWidth={true} onChange={event => setFirm(event.target.value)}/>
                <TextField label="Message" variant="filled" multiline rows={4} fullWidth={true} onChange={event => setMessage(event.target.value)}/>
                <Button variant="contained" type="submit" style={{marginRight: 10, marginTop: 10, backgroundColor: '#1E1E44', color: 'white'}}>  
                    Submit
                </Button>
                <Button variant="contained" onClick={handleContactClose} style={{marginLeft: 10, marginTop: 10}}>
                    Cancel
                </Button>
                </form>
            </DialogContent>
        </Dialog>
        <Dialog open={loginOpen} onClose={handleLoginClose} align="center">
        <DialogTitle><img src={logo} style={{width: 50}}/>
        <Typography style={{fontSize: 25}}>Welcome to Velladore</Typography>
        </DialogTitle>
          <DialogContent>
            <TextField
              align="left"
              variant="outlined"
              label="Email address"
              onChange={(event) => {
                setUsername(event.target.value);
              }}
              style={{marginBottom: 10}}
            />
            <br/>
            <TextField
            align="left"
            variant="outlined"
              type="password"
              label="Password"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
            <br />
            <Button variant="contained" type="submit" onClick={dummyAuth} style={{ marginTop: 10, backgroundColor: '#1E1E44', color: 'white', width: '60%'}}>
              Log in
            </Button>
            <br />
            <br/>
            <a href="#" style={{textDecoration: 'none'}}><Typography >Forgot your password?</Typography></a>
          </DialogContent>
      </Dialog>
      <Dialog open={registerOpen} onClose={handleRegisterClose} align="center">
                {/* <DialogTitle> Register </DialogTitle> */}
                <DialogContent>
                    <Typography style={{fontSize: 25}}> Register</Typography>
                    <form  onSubmit={handleRegisterSubmission}>
                        <TextField
                            label="Name"
                            fullWidth={true}
                            onChange={(event) => setName(event.target.value)}
                        />
                        <br />
                        <TextField
                            label="Email address"
                            type="email"
                            fullWidth={true}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        <br />
                        <TextField
                            label="Firm"
                            type="text"
                            fullWidth={true}
                            onChange={(event) => setFirm(event.target.value)}
                        />
                        <br />
                        <Button variant="contained" type="submit" style={{marginRight: 10, marginTop: 10,backgroundColor: '#1E1E44', color: 'white'}}> submit </Button>
                        <Button variant="contained" style={{marginLeft: 10, marginTop: 10}} onClick={handleRegisterClose}>
                            cancel
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
        <div className="sidebar" align="center">
            <div className="line-item" style={{marginTop: '80px'}} onClick={() => {hidebg(); setLoginOpen(true)}}>
                Log in
            </div>
            <div className="line-item" onClick={() => {hidebg(); setContactOpen(true)}}> 
                Contact
            </div>
            <div className="line-item" onClick={() => { hidebg(); setRegisterOpen(true)}}> 
                Register
            </div>
        </div>
                </>
            }
            </>
    )
}

export default Sidebar;
