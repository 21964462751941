import React from "react";
import {
  Grid,
  List,
  ListItem,
  Table,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import { getDownloaded } from "../../API";
import DealClosureConfirm from './DealClosureConfirm';

const DealClosure = () => {
  const [downloaded, setDownloaded] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [id, setId] = React.useState('');


  const handleClick = (name, id) => {
        setName(name);
        setId(id);
        setOpen(true);
  }


  React.useEffect(() => {
    getDownloaded()
      .then((res) => {
        setDownloaded(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Grid container>
      <Grid item xs={3}>
        <Paper>
            {!open && <>Please select deal that you have closed</>}
          <Table >
            {downloaded &&
              downloaded.map((item) => (
                <TableRow hover>
                  <TableCell style={{cursor: 'pointer'}} onClick={() => {handleClick(item['companyName'], item['_id'])}}>{item.companyName}</TableCell>
                </TableRow>
              ))}
          </Table>
        </Paper>
      </Grid>
      <DealClosureConfirm open={open} title={name}/>
    </Grid>
  );
};

export default DealClosure;
