import Typography from "@material-ui/core/Typography";
import React, {useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from '@material-ui/core/TableBody';
import TableRow from "@material-ui/core/TableRow";
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import html from '../../static_pages/findersfee';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { getDeals, addSavedDeal, removeSavedDeal } from '../../API';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import StarIcon from '@material-ui/icons/Star';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faCoffee, faCog, faDollarSign, faTshirt, faBatteryFull, faLaptop, faHouseUser, faPhone, faHospital, faCrop, faFile } from '@fortawesome/free-solid-svg-icons'
import DealPreview from './DealPreview';
import {revenue_ranges, ebitda_ranges} from './Helpers/FinancialRanges'

const headers = [
  "Project Name",
  "Interested",
  "EBITDA",
  "Revenue",
  "Industry",
  "Location",
  "Employee Count",
];

const numberWithCommas = (x) => {
  return ('$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
}

const iconReturner  = (item) => {
  let icon = null;

  switch (item['industry']) {
      case 'Financials':
          icon = faDollarSign
          break;
      case 'Materials':
          icon = faTshirt
          break;
      case 'Consumer Discretionary':
          icon = faDollarSign
          break;
      case 'Consumer Staples':
          icon = faCrop;
          break;
      case 'Health Care':
          icon = faHospital;
          break;        
      case 'Energy':
          icon = faBatteryFull;
          break;
      case 'Technology':
          icon = faLaptop;
          break;
      case 'Real Estate':
          icon = faHouseUser;
          break;
      case 'Communication':
          icon = faPhone;
          break;
      default:
          icon = null;
  }

  if (icon === null) {
      return 'loading';
  }
  return (
      <>
          <FontAwesomeIcon icon={icon}/> {' '} {item['industry']}
      </>
  )
}

const Deals = (props) => {

  const addSaved = (dealID, index) => {
    addSavedDeal(dealID)
    .then(res => {
      let holder = deals;
      holder[index].saved = true;
      setDeals([...holder]);
    })
    .catch(err => {
      console.log(err);
    })
  }

  const removeSaved = (dealID, index) => {
    removeSavedDeal(dealID)
    .then(res => {
      let holder = deals;
      holder[index].saved = false;
      setDeals([...holder]);
    })
    .catch(err => {
      console.log(err);
    })
  }

  const [preview, setPreview] = React.useState(null);
  const [graphData, setGraphData] = React.useState(null);
  const [deals, setDeals] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    setDeals(props.deals)
    if (!props.deals || preview === null) {
      return;
    }
    let info = []
    info.push({
      'year': 2018,
      'ebitda': deals[preview]['ebitda_2018'],
      'revenue': deals[preview]['revenue_2018']
    })
    info.push({
      'year': 2019,
      'ebitda': deals[preview]['ebitda_2019'],
      'revenue': deals[preview]['revenue_2019']
    })
    info.push({
      'year': 2020,
      'ebitda': deals[preview]['ebitda_2020'],
      'revenue': deals[preview]['revenue_2020']
    })
    setGraphData(info);

  }, [props.deals, preview, deals])

  const setClose = () => {
    setOpen(false);
  }

  const toggleLeft = () => {
    console.log(preview);
    setPreview(preview - 1);
  }
  const toggleRight = () => {
    console.log(preview);
    setPreview(preview + 1);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.handlePaginator(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSetPreview = (index) => {
    setOpen(true);
    setPreview(index);
    // console.log(deals[preview])
    let info = []
    info.push({
      'year': 2018,
      'ebitda': deals[index]['ebitda_2018'],
      'revenue': deals[index]['revenue_2018']
    })
    info.push({
      'year': 2019,
      'ebitda': deals[index]['ebitda_2019'],
      'revenue': deals[index]['revenue_2019']
    })
    info.push({
      'year': 2020,
      'ebitda': deals[index]['ebitda_2020'],
      'revenue': deals[index]['revenue_2020']
    })
    setGraphData(info);
  }

  return (
    <div >
      {deals === null && <Typography style={{marginLeft: 'auto', marginRight: 'auto'}}>Select the filter above to perform a search</Typography>}
      {deals !== null &&
      <>
      <Table size="small" style={{ maxWidth: '100%' }}>
        <TableHead>
          <TableRow style={{ backgroundColor: "#1E1E44", height: 55}} hover={true}>
            <TableCell align="left" style={{ fontWeight: 'bold', color: 'white' }}>{headers[0]}</TableCell>
            <TableCell align="left" style={{ fontWeight: 'bold', color: 'white' }}>{headers[2]}</TableCell>
            <TableCell align="left" style={{ fontWeight: 'bold', color: 'white' }}>{headers[3]}</TableCell>
            <TableCell align="left" style={{ fontWeight: 'bold', color: 'white' }}>{headers[4]}</TableCell>
            <TableCell align="left" style={{ fontWeight: 'bold', color: 'white' }}>{'Starred'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deals.slice(page * props.paginator, page *  props.paginator + props.paginator).map((item, index) => (
            <TableRow hover={true} style={{cursor: 'pointer'}} key={item['_id']} >
              {/* <TableCell> <Checkbox color="primary" checked={item[0]} /> </TableCell> */}
              <TableCell ><Link to={`/explore/${item['_id']}`}>Project {item['alias']}</Link></TableCell>
              <TableCell> <Link to={`/explore/${item['_id']}`}>{ebitda_ranges(item['ebitda_2020'])} </Link></TableCell>
              <TableCell ><Link to={`/explore/${item['_id']}`}>{revenue_ranges(item['revenue_2020'])}</Link></TableCell>
              <TableCell> <Link to={`/explore/${item['_id']}`}>{iconReturner(item)} </Link></TableCell>
              <TableCell>
                {deals[index].saved ? <StarIcon color='primary' onClick={() => {removeSaved(item['_id'], index)}}/> : <StarBorderIcon color="primary" onClick={() => {addSaved(item['_id'], index)}} /> }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
      count={deals.length}
      rowsPerPage={props.paginator}
      page={page}
      rowsPerPageOptions={[5, 10, 20]}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      </>
      }
      {/* {
        deals !== null && 
        <Card style={{ width: '100%', border: '1.5px solid black', paddingTop: 15 }} align="center">
          <Typography style={{ fontSize: 25 }}>
            {deals[preview]['companyName']}
          </Typography>
          <Typography style={{ marginRight: 5, marginLeft: 5, paddingTop: 8, paddingBottom: 8, borderRadius: '3px', border: '.5px solid black', width: '70%', marginBottom: 35 }}>
              {deals[preview]['productDescription']}
              </Typography>
          <Grid container  spacing={4}>
            <Grid item xs style={{textAlign: 'left', marginLeft: '10%'}}>
                <ul style={{listStylePosition: 'inside', fontSize: 18}}>
                  <li>
                    Industry: {deals[preview]['industry']}
                  </li>
                  <li>
                    State: {deals[preview]['locationState']}
                  </li>
                  <li>
                    EBITDA Range: {deals[preview]['ebitda_2020']}
                  </li>
                  <li>
                    Revenue Range: {deals[preview]['revenue_2020']}
                  </li>
                </ul>
              <div style={{border: '1px solid black', borderRadius: '4px', marginLeft: 10, marginRight: 10, marginTop: 15}} align='center'>
                <h2>
                  Files Available to Download
                </h2>
                <Grid container spacing={2}>
                  {deals[preview]['originalName'].map(item => 
                    <Grid item xs style={{padding: 10}}>
                      <FontAwesomeIcon icon={faFile} size='30px'/> {'\n'}{item}
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>
            <Grid item xs>
            <h2>EBITDA</h2>
        <LineChart width={500} height={235} data={graphData} margin={{ top: 5, right: 20, bottom: 5, left: 15 }}>
          <Line type="monotone" dataKey="ebitda" stroke="#8884d8" />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="year" />
          <YAxis />
          <Tooltip />
        </LineChart>
        <h2>Revenue</h2>
        <LineChart width={500} height={250} data={graphData} margin={{ top: 5, right: 20, bottom: 5, left: 15 }}>
          <Line type="monotone" dataKey="revenue" stroke="#8884d8" />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="year" />
          <YAxis />
          <Tooltip />
        </LineChart>
            </Grid>
          </Grid>
        <div style={{bottom: 10, display: 'flex', marginLeft: '40%', marginRight: '40%'}}>
           <div style={{ cursor: 'pointer', border: '.5px solid gray', borderRadius: '3px', padding: '6px' }} onClick={() => { setFindersDoc(true) }}> Review Finder's Fee Agreement</div>
        </div>
      </Card>
      } */}
      <DealPreview addSaved={addSaved} removeSaved={removeSaved} toggleRight={toggleRight} toggleLeft={toggleLeft} open={open} preview={preview} graphData={graphData} deals={deals} setClose={setClose} user={props.user}/>
    </div>
  );
};

export { Deals, iconReturner };
