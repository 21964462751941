import DehazeIcon from '@material-ui/icons/Dehaze';
import React from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import './Burger.css';
import Sidebar from './Sidebar';

const MobileLanding = () => {

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    }

    return (
        <>
            <HamburgerMenu
                className="ham"
                menuClicked={handleClick}
                isOpen={open}
                width={40}
                height={30}
                strokeWidth={1}
                rotate={0}
                color='white'
                borderRadius={0}
                animationDuration={0.5}
            />

            <Sidebar open={open}/>



            {/* <DehazeIcon className="menu_button burger"/> */}
            {/* <a className="menu_button">
                <div className="burger"/>
            </a> */}
      </>
    )
}




export default MobileLanding;
