import React from 'react';
import { TextField, Button, DialogTitle, Typography } from '@material-ui/core';
import logo from "../static_pages/navy_logo.png";
import '../style/Contact.css'

const encode = (data) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

const Contact = () => {

    const [name, setName] = React.useState("");
    const [firm, setFirm] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [email, setEmail] = React.useState("");

    const handleSubmission = (e) => {
        console.log(name, firm, email);
        fetch("/", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: encode({
            "form-name": "contact",
            name: name,
            email: email,
            firm: firm,
            message: message,
          }),
        })
          .then(() => {
            console.log("close contact");
          })
          .catch((error) => {
            console.log(error);
          });
        e.preventDefault();
      };
    return (
        <div style={{ width: '100%', minWidth: 220, padding: 0, marginBottom: 0, paddingTop: '20vh', minHeight: '100vh'}} align="left">
            <div className="animation-element slide-left testimonial" style={{background: '#1E1E44', marginBottom: 70}}>
            <span style={{ fontSize: 60, color: 'white', fontWeight: 'bold', marginLeft: 100}}>Get in <span style={{color: '#7d3a98'}}> Touch </span></span>
            </div>
            <div className="contact_footer" style={{paddingTop: '15vh', paddingBottom: '15vh'}}>
            <div style={{marginLeft: '20%', marginRight: '20%', borderRadius: 15, border: '.25px solid grey', padding: 12, background: 'white'}} className="animation-element slide-left testimonial">
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} align="center">
          <img src={logo} style={{ width: 50, marginRight: 20}} />
        </div>
            <form onSubmit={handleSubmission}>
            <TextField
              label="Name"
              fullWidth={true}
              onChange={(event) => setName(event.target.value)}
            />
            <TextField
              label="Email address"
              type="email"
              fullWidth={true}
              onChange={(event) => setEmail(event.target.value)}
            />
            <TextField
              label="Firm"
              type="text"
              fullWidth={true}
              onChange={(event) => setFirm(event.target.value)}
            />
            <TextField
              label="Message"
              multiline
              rows={4}
              fullWidth={true}
              onChange={(event) => setMessage(event.target.value)}
            />
            <br />
            <div align="center">
            <Button
              variant="contained"
              type="submit"
              style={{
                marginRight: 10,
                marginTop: 10,
                backgroundColor: "#1E1E44",
                color: "white",
                textTransform: "none",
              }}
            >
              Submit
            </Button>
            </div>

          </form>
          </div>
            </div>

 
          <div className="footing">
        <p align="center" style={{ fontSize: "17px", color: "white" }}>
          &copy; 2021 All Rights Reserved <br />
          Velladore LLC
        </p>
      </div>
      </div>
    )
}

export default Contact;
