import React from 'react';
import { Button, Dialog, DialogActions, DialogContent} from '@material-ui/core';

const LogoutConfirmation = (props) => {
    return (
        <Dialog open={props.open} align="center">
            <DialogContent style={{fontSize: 17}}>
                Are you sure you want to log out?
            </DialogContent>
            <DialogActions align="center">
                <Button style={{textTransform: 'none', width: '30%', marginLeft: '15%', marginRight: '5%', fontSize: 17, backgroundColor: '#d3d3d3'}} variant="outlined" onClick={props.logout}>
                    Yes
                </Button>
                <Button style={{textTransform: 'none', width: '30%', marginLeft: '5%', marginRight: '15%', fontSize: 17, backgroundColor: '#1E1E44', color: 'white'}} variant="outlined" onClick={props.closer}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default LogoutConfirmation;
