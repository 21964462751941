import React from "react";
import NDAdocument from "../NDAdocument";
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
} from "@material-ui/core";
import { sendSignature } from '../../API';
import Slide from "@material-ui/core/Slide";
import { useHistory } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NDAOpener = (props) => {
  const history = useHistory();

  const [sig, setSig] = React.useState([]);

  const sender = () => {
    sendSignature(props.dealid, sig)
    .then(res => {
      setSig(null);
      props.setFindersDoc(false)
      history.push('/my-deals');
    })
    .catch(err => {
      console.log(err);
    })
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      open={props.findersDoc}
      onClose={() => {
        props.setFindersDoc(false);
      }}
      fullScreen
      align="center"
    >
      <DialogContent>
        <NDAdocument setSig={setSig} dealid={props.dealid} user={props.user} />
      </DialogContent>
      <DialogActions align="center">
        <Button
          variant="contained"
          onClick={() => {
            props.setFindersDoc(false);
          }}
          style={{
            marginLeft: 10,
            marginTop: 10,
          }}
        >
          close
        </Button>
        <Button
          variant="contained"
          style={{
            marginLeft: 10,
            marginTop: 10,
          }}
          disabled={!sig || sig.length === 0}
          onClick={sender}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NDAOpener;
