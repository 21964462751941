import React from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  List, ListItem, ListItemIcon, ListItemText
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from "recharts";
import LockIcon from "@material-ui/icons/Lock";
import "../../style/DealPreview.css";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import NDAOpener from "./NDAOpener";
import VisibilityIcon from '@material-ui/icons/Visibility';
import DateRangeIcon from '@material-ui/icons/DateRange';
import GetAppIcon from '@material-ui/icons/GetApp';
import {extractFileType} from './Upload';
import { revenue_ranges, ebitda_ranges } from './Helpers/FinancialRanges';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const numberWithCommas = (x) => {
  return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const DealPreview = (props) => {
  const [findersDoc, setFindersDoc] = React.useState(false);

  const fileNames = () => (
    <Grid container spacing={2}>
      {props.deals[props.preview]["originalName"].map((item) => (
        <Grid item xs style={{ padding: 10 }}>
          <FontAwesomeIcon icon={faFile} size="30px" /> {"\n"}
          {item}
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      {(props.preview !== null && props.preview !== undefined) && (
        <Dialog
          open={props.open}
          maxWidth="xl"
          fullWidth={true}
          onClose={props.setClose}
        >
          <DialogTitle>
            <Grid container>
              <Grid item xs={1} align="left">
                <IconButton
                  onClick={props.toggleLeft}
                  disabled={props.preview === 0 ? true : false}
                >
                  <FontAwesomeIcon
                    style={{ fontSize: 40 }}
                    icon={faArrowAltCircleLeft}
                  />
                </IconButton>
              </Grid>
              <Grid item xs={10} align="center" style={{fontSize: 40}}>
                Project {" "}
                {props.deals[props.preview]["alias"]}
                {props.deals[props.preview].saved ? <StarIcon style={{fontSize: 35, marginLeft: 10, cursor: 'pointer'}} color='primary' onClick={() => {props.removeSaved(props.deals[props.preview]['_id'], props.preview)}}/> : <StarBorderIcon color="primary" style={{fontSize: 35, marginLeft: 10, cursor: 'pointer'}} onClick={() => {props.addSaved(props.deals[props.preview]['_id'], props.preview)}} /> }
              </Grid>
              <Grid item xs={1} align="right">
                <IconButton
                  onClick={props.toggleRight}
                  disabled={
                    props.preview === props.deals.length - 1 ? true : false
                  }
                >
                  <FontAwesomeIcon
                    style={{ fontSize: 40 }}
                    icon={faArrowAltCircleRight}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent align="center" style={{ overflowY: "scroll" }}>
            {/* <Typography
              style={{
                marginRight: 5,
                marginLeft: 5,
                paddingTop: 8,
                paddingBottom: 8,
                borderRadius: "3px",
                border: ".5px solid black",
                width: "70%",
                marginBottom: 35,
              }}
            >
              {props.deals[props.preview]["productDescription"]}
            </Typography> */}
            <Grid container spacing={2}>
              <Grid item xs={1}/>
              <Grid item xs={4} style={{ textAlign: "left" }}>
                <Card align="center" style={{ padding: 8 }}>
                  <CardContent>
                    <h2 align="center">Basic Company Information</h2>
                    <div style={{ textAlign: "left" }}>
                      <ul style={{ listStylePosition: "inside", fontSize: 18 }}>
                      <li>
                          <strong>Description:</strong>{" "}
                          {props.deals[props.preview]["productDescription"]}
                        </li>
                        <li>
                          <strong>Industry:</strong>{" "}
                          {props.deals[props.preview]["industry"]}
                        </li>
                        <li>
                          <strong> State:</strong>{" "}
                          {props.deals[props.preview]["state"]}
                        </li>
                        <li>
                          <strong>EBITDA Range:</strong>{" "}
                          {
                            ebitda_ranges(props.deals[props.preview]["ebitda_2020"])
                          }
                        </li>
                        <li>
                          <strong>Revenue Range:</strong>{" "}
                          {
                            revenue_ranges(props.deals[props.preview]["revenue_2020"])
                          }
                        </li>
                      </ul>
                    </div>
                  </CardContent>
                </Card>
                <Card
                  style={{ padding: 8, marginTop: 12 }}
                >
                  <h2 align="center">Files Available to Download</h2>
                  <CardContent
                    align={
                      props.deals[props.preview].downloaded ? "" : "center"
                    }
                  >
                    There is a total of {props.deals[props.preview]['uuidName'].length} file(s), covering the following diligence expectations: <br/>
                    <List>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon style={{color: 'green'}}/>
                        </ListItemIcon>
                        <ListItemText>
                          Full financial statements between 2018 and 2020
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon style={{color: 'green'}}/>
                        </ListItemIcon>
                        <ListItemText>
                          Full industry analysis including 12 comparable firms in the industry
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckBoxIcon style={{color: 'green'}}/>
                        </ListItemIcon>
                        <ListItemText>
                          In-depth leadership analysis
                        </ListItemText>
                      </ListItem>
                    </List>

                    {/* {props.deals[props.preview]['uuidName'] &&
                    <Grid container>
                      {props.deals[props.preview]['uuidName'].map(thing =>
                        <Grid item xs={6}>
                          <Tooltip title="Files are locked until deal is signed">
                          <FontAwesomeIcon style={{fontSize: 20}} icon={extractFileType(thing)[0]}/>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid> */}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card style={{ padding: 1, height: "100%" }}>
                  <p align="center">EBITDA</p>
                  <LineChart
                    width={400}
                    height={170}
                    data={props.graphData}
                    margin={{ right: 20, left: 15 }}
                  >
                    <Line type="monotone" dataKey="ebitda" stroke="#8884d8" />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="year" />
                    {/* <YAxis /> */}
                  </LineChart>
                  <p align="center">Revenue</p>
                  <LineChart
                    width={400}
                    height={170}
                    data={props.graphData}
                    margin={{ right: 20, left: 15 }}
                  >
                    <Line type="monotone" dataKey="revenue" stroke="#8884d8" />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="year" />
                    {/* <YAxis /> */}
                  </LineChart>
                </Card>
              </Grid>
              <Grid item xs={1}>
                <Card>
                  <CardContent>
                    <Tooltip title="Number of Views" placement="top">
                    <VisibilityIcon/>
                    </Tooltip>
                    <br/> 13
                  </CardContent>
                </Card>
                <Card style={{marginTop: 10}} >
                  <CardContent>
                    <Tooltip title="Number of Downloads" placement="top">
                    <GetAppIcon/>
                    </Tooltip>
                    <br/> 4
                  </CardContent>
                </Card>
                <Card style={{marginTop: 10}}>
                  <CardContent>
                    <Tooltip title="Date deal went live on Velladore" placement="top">
                    <DateRangeIcon/> 
                    </Tooltip>
                    <br/> 12/24/2020
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions align="center">
            <Button
              onClick={() => {
                setFindersDoc(true);
              }}
              style={{textTransform: 'none'}}
              variant="outlined"
            >
              Next step
            </Button>
          </DialogActions>
          <NDAOpener
            user={props.user}
            dealid={props.deals[props.preview]["_id"]}
            findersDoc={findersDoc}
            setFindersDoc={setFindersDoc}
          />
        </Dialog>
      )}
    </>
  );
};

export default DealPreview;
