import React from "react";
import {
  Card,
  Grid,
  CardMedia,
  CardContent,
  Paper,
  Typography,
} from "@material-ui/core";
import kelan from "../static_pages/kelan.jpg";
import andrew from "../static_pages/andrew.jpg";
import gracie from "../static_pages/gracie.jpg";
import "../style/Team.css";

export default function Team() {
  return (
    <div style={{ marginBottom: 0, paddingBottom: "15vh", backgroundColor: 'white', paddingRight: 0, paddingLeft: 0, minHeight: '100vh' }}>
                  <div
            style={{backgroundColor: '#1E1E44', paddingBottom: '15vh', paddingTop: '15vh'}}
          >
      <h2 align="left" className="animation-element slide-left testimonial" style={{ color: 'white', margin: 20, marginTop: 0, fontSize: 65}}> Meet the <br/><span style={{color: '#7d3a98'}}> Velladore </span> Team </h2>
      </div>
      <Grid container spacing={0} style={{backgroundColor: 'white', paddingTop: 50, paddingRight: 15, paddingLeft: 15}}>
      <Grid item xs={12} sm={6} md={4} className="box animation-element slide-left testimonial">
            <CardMedia
              component="img"
              image={kelan}
              style={{ objectFit: "contain", maxHeight: 280 }}
            />
            <CardContent align="center">
              <div className="name_text">Kelan Anzures</div>
              <div className="name_subtext">CEO, Co-Founder</div>
            </CardContent>
            <div id="move-in-to-place">
              Kelan is the strategic leader of Velladore. He currently serves as
              an Investment Banking Analyst at Morgan Stanley within the
              Financial Institutions Group, where he supports FinTech,
              Diversified Finance, and Private Equity clients on a variety of
              M&A, equity, and debt transactions. Kelan graduated magna cum
              laude from Vanderbilt University with majors in Economics and
              Human & Organizational Development. In his free time, Kelan enjoys
              mountaineering, traveling, and binge-watching Netflix.{" "}
            </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className="box animation-element slide-left testimonial">
            <CardMedia
              component="img"
              image={andrew}
              style={{ objectFit: "contain", maxHeight: 280 }}
            />
            <CardContent align="center">
              <div className="name_text">Andrew Lanham</div>
              <div className="name_subtext">CTO, Co-Founder</div>
            </CardContent>
            <div id="move-in-to-place">
              Andrew is the undisputed architect and designer of the Velladore
              platform. As a full-stack developer, he has worked on a variety of
              projects and has held roles at Maxar Technologies and SettleTop.
              Upon graduating from the University of Michigan with a degree in
              Computer Science, Andrew will be a cloud consultant at Maven Wave.
              When he needs to unplug, you can find him out exploring and
              relaxing in the mountains of Colorado.
            </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="box animation-element slide-left testimonial">
            <CardMedia
              component="img"
              image={gracie}
              style={{ objectFit: "contain", maxHeight: 280 }}
            />
            <CardContent align="center">
              <div className="name_text">Gracie O'Rorke</div>
              <div className="name_subtext">COO</div>
            </CardContent>
            <div id="move-in-to-place">
              Gracie is the creator and facilitator of Velladore’s internal
              processes. She is passionate about building client relationships,
              optimizing organizational efficiency, and implementing innovative
              solutions. Gracie is a student at Vanderbilt University double
              majoring in Human & Organizational Development and English. In her
              spare time, she loves to hike, read, and attempt to keep her house
              plants alive.{" "}
            </div>
        </Grid>
      </Grid>
      { /*
      <Grid container spacing={3} style={{background: 'white'}}>
        <Grid item xs={12} sm={6} md={4}>
          <Card className="box animation-element slide-left testimonial">
            <CardMedia
              component="img"
              image={kelan}
              style={{ objectFit: "contain", maxHeight: 280 }}
            />
            <CardContent align="center">
              <div style={{ fontSize: 25 }}>Kelan Anzures</div>
              <div style={{ fontSize: 17, color: "grey" }}>CEO, Co-Founder</div>
            </CardContent>
            <div id="move-in-to-place">
              Kelan is the strategic leader of Velladore. He currently serves as
              an Investment Banking Analyst at Morgan Stanley within the
              Financial Institutions Group, where he supports FinTech,
              Diversified Finance, and Private Equity clients on a variety of
              M&A, equity, and debt transactions. Kelan graduated magna cum
              laude from Vanderbilt University with majors in Economics and
              Human & Organizational Development. In his free time, Kelan enjoys
              mountaineering, traveling, and binge-watching Netflix.{" "}
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        <Card className="box animation-element slide-left testimonial">
            <CardMedia
              component="img"
              image={andrew}
              style={{ objectFit: "contain", maxHeight: 280 }}
            />
            <CardContent align="center">
              <div style={{ fontSize: 25 }}>Andrew Lanham</div>
              <div style={{ fontSize: 17, color: "grey" }}>CTO, Co-Founder</div>
            </CardContent>
            <div id="move-in-to-place">
              Andrew is the undisputed architect and designer of the Velladore
              platform. As a full-stack developer, he has worked on a variety of
              projects and has held roles at Maxar Technologies and SettleTop.
              Upon graduating from the University of Michigan with a degree in
              Computer Science, Andrew will be a cloud consultant at Maven Wave.
              When he needs to unplug, you can find him out exploring and
              relaxing in the mountains of Colorado.
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        <Card className="box animation-element slide-left testimonial">
            <CardMedia
              component="img"
              image={gracie}
              style={{ objectFit: "contain", maxHeight: 280 }}
            />
            <CardContent align="center">
              <div style={{ fontSize: 25 }}>Gracie O'Rorke</div>
              <div style={{ fontSize: 17, color: "grey" }}>COO</div>
            </CardContent>
            <div id="move-in-to-place">
              Gracie is the creator and facilitator of Velladore’s internal
              processes. She is passionate about building client relationships,
              optimizing organizational efficiency, and implementing innovative
              solutions. Gracie is a student at Vanderbilt University double
              majoring in Human & Organizational Development and English. In her
              spare time, she loves to hike, read, and attempt to keep her house
              plants alive.{" "}
            </div>
          </Card>
        </Grid>
      </Grid> */}
    </div>
  );
}
