import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import "../style/Homepage.css";
import TextField from "@material-ui/core/TextField";
import "../style/Login.css";
import Alert from "@material-ui/lab/Alert";
import { Link, useHistory } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import Typography from '@material-ui/core/Typography';
import logo from '../static_pages/navy_logo.png';

const encode = (data) => {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
};

const Register = () => {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState("");
    const [firm, setFirm] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [alert, setAlert] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmission = e => {
        console.log(name, firm, email);
        fetch("/", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: encode({
                "form-name": "register",
                name: name,
                email: email,
                firm: firm,
            })
        })
            .then(() => {
                handleClose();
                console.log("close register");
                setAlert(true);
            })
            .catch((error) => {
                console.log(error);
            });
        e.preventDefault();
    };

    return (
        <div>
            {alert && (
                <Alert style={{ position: 'relative', maxWidth: 600, marginLeft: '30%', marginRight: '30%'}} onClose={() => { setAlert(false)}}>
                    Thank you - we will get back to you within 36 hours!
                </Alert>
            )}
            <div className="register">
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpen(true);
                    }}
                    style={{textTransform: 'none', fontSize: 20}}
                >
                    Register for Early Access
                </Button>
            </div>

            <Dialog open={open} onClose={handleClose} align="center" maxWidth={'md'} fullWidth={true}>
                {/* <DialogTitle> Register </DialogTitle> */}
                <DialogContent>
                    <DialogTitle>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} align="center">
                 <img src={logo} align="center" style={{width: 34, marginRight: 10}} alt="logo"/>
                  <Typography style={{fontSize: 25}} align="center">Register</Typography>
                 </div>
                    </DialogTitle>
                    <form  onSubmit={handleSubmission}>
                    <TextField
                            label="Firm"
                            type="text"
                            fullWidth={true}
                            onChange={(event) => setFirm(event.target.value)}
                        />
                        <br />
                        <TextField
                            label="Name"
                            fullWidth={true}
                            onChange={(event) => setName(event.target.value)}
                        />
                        <br />
                        <TextField
                            label="Email address"
                            type="email"
                            fullWidth={true}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        <br />
                        <br />
                        <Button variant="contained" type="submit" style={{marginRight: 10, marginTop: 10,backgroundColor: '#1E1E44', color: 'white', textTransform: 'none'}}> Submit </Button>
                        <Button variant="contained" style={{marginLeft: 10, marginTop: 10, textTransform: 'none'}} onClick={handleClose}>
                            Cancel
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Register;
