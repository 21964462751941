import React from "react";
import Homepage from "./Components/Homepage";
import Help from "./Components/Portal/Help";
import Profile from "./Components/Portal/Profile";
import Account from "./Components/Portal/Account";
import ProtectedRoute from "./Components/ProtectedRoute";
import MyDealsPreview from "../src/Components/Portal/MyDealsPreview";
import Dashboard from "./Components/Portal/Dashboard";
import Explore from "./Components/Portal/Explore";
import { Upload } from "./Components/Portal/Upload";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import UploadSelector from "./Components/Portal/UploadSelector";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MyDeals from "./Components/Portal/MyDeals";
import DealPreview from "./Components/Portal/DealPreview2";
import { Deals } from "./Components/Portal/Deals2";
import DealClosure from './Components/Portal/DealClosure'

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const App = () => {
  const classes = useStyles();

  const [profile, setProfile] = React.useState(null);
  const [paginator, setPaginator] = React.useState(5);

  const handleProfile = (input) => {
    setProfile(input);
  };

  const handlePaginator = (num) => {
    setPaginator(num);
  };

  return (
    <Router>
      <Switch>
        <Route path="/home" exact={true} component={Homepage} />
        <ProtectedRoute path="/">
          <>
            <Profile handleProfile={handleProfile} />
            <Switch>
              <main
                className={classes.content}
                style={{ marginLeft: 240, marginTop: 60 }}
              >
                <ProtectedRoute exact={true} path="/pipeline">
                  <Dashboard />
                </ProtectedRoute>
                <Route path="/" exact>
                  <Redirect to="/pipeline" />
                </Route>
                <ProtectedRoute exact path="/explore">
                  <Explore
                    user={profile}
                    paginator={paginator}
                    handlePaginator={handlePaginator}
                  />
                </ProtectedRoute>
                <ProtectedRoute path="/help">
                  <Help />
                </ProtectedRoute>
                <ProtectedRoute exact path="/upload/new-deal">
                  <Upload />
                </ProtectedRoute>
                <ProtectedRoute exact path="/upload">
                  <UploadSelector
                    handlePaginator={handlePaginator}
                    paginator={paginator}
                  />
                </ProtectedRoute>
                <ProtectedRoute exact path="/explore/deal/:dealid">
                  <DealPreview user={profile}/>
                </ProtectedRoute>
                <ProtectedRoute exact path="/my-deals">
                  {profile && (
                    <MyDeals
                      firmCity={profile.city}
                      coordinates={profile.firmCoordinates}
                    />
                  )}
                </ProtectedRoute>
                <ProtectedRoute exact path="/account">
                  {profile && <Account user={profile} />}
                </ProtectedRoute>
                <ProtectedRoute exact path="/upload/notification">
                  <Redirect to={{
                    pathname: "/upload",
                    state: { previewOpen: true}
                  }}
                  />
                </ProtectedRoute>
                  <ProtectedRoute exact path="/closeddeal">
                    <DealClosure />
                  </ProtectedRoute>
                <ProtectedRoute path="/company">
                  <Profile company={true} />
                </ProtectedRoute>
              </main>
            </Switch>
          </>
        </ProtectedRoute>
      </Switch>
    </Router>
  );
};

export default App;
