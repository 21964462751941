import React from "react";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Button,
} from "@material-ui/core";
import { markNotificationRead } from "../../API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px + 5px)`,
      marginLeft: `calc(${drawerWidth}px - 6px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const NotificationsBar = (props) => {
  const classes = useStyles();

  const [read, setRead] = React.useState(null);

  React.useEffect(() => {
    let holder = [];
    props.notifications.forEach((item) => {
      holder.push(item["read"]);
    });
    setRead(holder);
  }, [props]);

  const handleRead = (notID, index) => {
    console.log(notID);
    markNotificationRead(notID)
      .then((res) => {
        props.editRead(index);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Drawer
      open={props.drawerOpen}
      onClose={props.handleDrawerClose}
      anchor={"right"}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {props.notifications.length > 0 &&
        props.notifications.map((item, index) => (
          <>
              <Accordion>
                <AccordionSummary>
                  <ListItemIcon>
                    <FiberManualRecordIcon
                      style={{
                        color: item.read ? "white" : "red",
                        fontSize: 12,
                      }}
                    />
                  </ListItemIcon>
                  {item.header} <br />
                </AccordionSummary>
                <ListItemText>
                  <AccordionDetails align="center" style={{ display: "grid" }}>
                    {item.subtext}
                    <br />
                    <Link
                      to={item.link}
                      onClick={() => {
                        handleRead(item["_id"], index);
                        props.handleDrawerClose();
                      }}
                      style={{textDecoration: 'none'}}

                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none" }}
                        endIcon={
                          <FontAwesomeIcon icon={faArrowAltCircleRight} />
                        }
                      >
                        Proceed
                      </Button>
                    </Link>
                  </AccordionDetails>
                </ListItemText>
              </Accordion>
          </>
        ))}
    </Drawer>
  );
};

export default NotificationsBar;
