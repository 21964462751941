import Cookies from 'js-cookie';
import { industry_array } from './Models/Industry';
const axios = require('axios');
//const fetch = require('fetch');

let ip = 'localhost';
let port= '1209';

const local = false;

let base = `http://${ip}:${port}`;

if (!local) {
    // base=`https://velladorebackend.azurewebsites.net`
    base = `https://velladore.herokuapp.com`
}

const connect = () => axios.create({
    baseURL: `http://${ip}:${port}`,
    responseType: 'json'
});

const login = (email, password) => {

    return axios({
        method: 'post',
        url: `${base}/api/users/login/`,
        data: {
            email: email,
            password: password
        },
        headers: {
            'Content-type': 'application/json'
        }
    });
}

const logout = () => {

}

const queryDeals = () => {

}

const uploadDeal = (file) => {
    let config = {        
        headers: {
        'Content-type': 'multipart/form-data',
        'authorization': `Token ${Cookies.get('userInfo')}`
        }
    }
    console.log('uploading deal');
    return axios.post(`${base}/api/deals/upload/`, file, config);
}

const getProfileInfo = () => {
    console.log('getting profile info');
    return axios({
        method: 'get',
        url: `${base}/api/users/profile/`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    });
}

const getDeals = (revenue, ebitda, industry, location, favorited) => {
    console.log('getting current deals');
    console.log(favorited);

    return axios({
        method: 'get',
        url: `${base}/api/deals/download/`,
        params: {
            'rMin': revenue[0],
            'rMax': revenue[1],
            'eMin': ebitda[0],
            'eMax': ebitda[1],
            'favorited': favorited
        },
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const getUserDeals = () => {
    return axios({
        method: 'get',
        url: `${base}/api/deals/getuserdeals/`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const addSavedDeal = (dealId) => {
    console.log('saving deal');
    return axios({
        method: 'post',
        data : {'dealId': dealId},
        url: `${base}/api/deals/addsaved/`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const removeSavedDeal = (dealId) => {
    console.log('removing deal');
    return axios({
        method: 'delete',
        data : {'dealId': dealId},
        url: `${base}/api/deals/removesaved/`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const changeLanes = (starting_lane, ending_lane, dealId) => {
    console.log('changing lanes');
    return axios({
        method: 'put',
        url: `${base}/api/deals/changelanes/`,
        params: {
            'dealId': dealId,
            'startingLane': starting_lane,
            'endingLane': ending_lane,
        },
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const getDealsById = (idArray) => {
    return axios({
        method: 'get',
        url: `${base}/api/deals/getdealbyid/`,
        params: {
            'idArray': idArray
        },
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const getUploaded = () => {
    return axios({
        method: 'get',
        url: `${base}/api/deals/getuploaded/`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const getDownloaded = () => {
    return axios({
        method: 'get',
        url: `${base}/api/deals/getdownloaded/`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const downloadFile = (fileName) => {
    return axios({
        method: 'get',
        url: `${base}/api/deals/downloadfile/`,
        params: {
            'file': fileName
        },
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const sendSignature = (dealID, signatureArray) => {
    console.log(dealID);
    console.log(signatureArray)
    return axios({
        method: 'post',
        data: {
            'dealid': dealID,
            'signature': signatureArray
        },
        url: `${base}/api/deals/uploadsignature/`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const editInvestmentCriteria = (revenue, ebitda) => {
    return axios({
        method: 'post',
        data: {
            'revenueMin': revenue[0],
            'revenueMax': revenue[1],
            'ebitdaMin': ebitda[0],
            'ebitdaMax': ebitda[1],
        },
        url: `${base}/api/users/changedealprofile/`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const changeContactProfile = (changes) => {
    return axios({
        method: 'post',
        data: {
            phone: changes['phone'],
            name: changes['name'],
            title: changes['title']
        },
        url: `${base}/api/users/changeContactProfile/`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const changeIndAndLoc = (industry, location) => {
    return axios({
        method: 'post',
        data: {
            industry: industry,
            location: location
        },
        url: `${base}/api/users/changeindandlocation`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const getNotes = (dealid) => {
    return axios({
        method: 'get',
        params: {
            dealid: dealid
        },
        url: `${base}/api/deals/getnotes`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const editNote = (changes, dealid) => {
    return axios({
        method: 'patch',
        params: {
            dealid: dealid,
            incoming: changes
        },
        url: `${base}/api/deals/editnote`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const deleteNote = (changes, dealid) => {
    return axios({
        method: 'patch',
        params: {
            dealid: dealid,
            incoming: changes
        },
        url: `${base}/api/deals/editnote`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const getNotifications = () => {
    return axios({
        method: 'get',
        url: `${base}/api/notifications/getnotifications`,
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const markNotificationRead = (notificationId) => {
    return axios({
        method: 'patch',
        url: `${base}/api/notifications/marknotificationread`,
        params: {
            notificationid: notificationId
        },
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

const getDealActions = (dealId) => {
    return axios({
        method: 'get',
        url: `${base}/api/deals/getactions`,
        params: {
            dealid: dealId
        },
        headers: {
            'Content-type': 'application/json',
            'authorization': `Token ${Cookies.get('userInfo')}`
        }
    })
}

export { ip, port, connect, login, getProfileInfo, uploadDeal, getDeals, getUserDeals, getDealsById, addSavedDeal, removeSavedDeal, getUploaded, getDownloaded, changeLanes, sendSignature, downloadFile, editInvestmentCriteria, changeContactProfile, getNotes, editNote, deleteNote, getNotifications, markNotificationRead, getDealActions, changeIndAndLoc };
