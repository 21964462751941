// @flow

import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'

const ProtectedRoute = (props) => {

    const loggedIn = !!Cookies.get('userInfo');

    const renderRoute = () => {
        const children = React.Children.map(props.children, (child) => {
            return React.cloneElement(child, { ...props })
        })

        if (loggedIn) {
            return children
        } else {
            return (
                <Redirect to={{
                    pathname: '/home'
                }}
                />
            )
        }
    }

    return (
        <Route exact={props.exact} path={props.path} render={renderRoute}/>
    )
}

export default ProtectedRoute;
