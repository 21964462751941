import React from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UploadStatus from "./UploadStatus";
import { extractFileType } from "./Upload";
import { downloadFile } from "../../API";
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from "recharts";
import MyDealsPreview from "./MyDealsPreview";
import "../../style/MyDeals.css";
import { iconReturner } from "./Deals";
import ModalDragger from './DraggableModal';
import { getUploaded, getDownloaded, getNotifications } from "../../API";

const MyDeals = (props) => {
  const [deals, setDeals] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [previewIndex, setPreviewIndex] = React.useState(0);



  const [modals, setModals] = React.useState([]);

  React.useEffect(() => {
    getDownloaded()
    .then((res) => {
      setDeals(res.data);
    })
    .catch((err) => {
      console.log(err);
    });

  }, [props])

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleTileClick = (event) => {
      console.log(event)
      setModals([...modals, event]);
  }
    // setData(props.deals[event]);
    // setDialogOpen(true);
    // setPreviewIndex(event);

  const toggleLeft = () => {
      setPreviewIndex(previewIndex - 1);
  }

  const toggleRight = () => {
    setPreviewIndex(previewIndex + 1);
}

  return (
    <>
      <Grid container spacing={1}>
        {(!deals || deals.length === 0) &&
            <div align="center">
                  You have not downloaded any deals yet.
            </div>
        }
        {deals &&
          deals.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Card
                className="tile"
                align="center"
                onClick={() => {
                  handleTileClick(index);
                }}
              >
                {/* <Select
                        value={item['']}
                        >

                        </Select> */}
                <CardContent>
                  <h2 style={{ marginTop: "none" }}>{item["companyName"]}</h2>
                  <p>{`${item["city"]}, ${item["state"]}`}</p>
                  <p>{iconReturner(item)}</p>
                  <p>{item["description"]}</p>
                  {/* <Accordion>
                                <AccordionSummary>
                                    Description
                                </AccordionSummary>
                                <AccordionDetails>
                                    {item['description']}
                                </AccordionDetails>
                            </Accordion> */}
                  {/* <Accordion>
                                <AccordionSummary>
                                    Files:
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                    {item['originalName'].map((document, index) =>
                                        <Grid item xs={6} align="center" style={{marginTop: '10px'}}>
                                            {<FontAwesomeIcon style={{fontSize: 25, cursor: 'pointer'}} onClick={() => downloadDocument(item['uuidName'][index])} icon={extractFileType(item['originalName'][index])}/>}
                                            <br/>
                                            {item['originalName'][index]}
                                        </Grid>
                                    )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion> */}
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
      {
          modals.map((item) =>
              <ModalDragger deals={deals}
              index={item}         firmCity={props.firmCity}
              coordinates={props.coordinates}
              data={data}/>
          )
      }
      {/* <MyDealsPreview
        firmCity={props.firmCity}
        coordinates={props.coordinates}
        data={data}
        toggleRight={toggleRight}
        toggleLeft={toggleLeft}
        open={dialogOpen}
        deals={props.deals}
        index={previewIndex}
        handleClose={handleClose}
      /> */}
    </>
  );
};

export default MyDeals;
