import React, { useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { Button, CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Link, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import { Route, Redirect } from "react-router-dom";
import { login } from "../API";
import logo from "../static_pages/navy_logo.png";
import "../style/Login.css";
import Cookies from "js-cookie";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const Login = () => {
  const [contactOpen, setContactOpen] = React.useState(false);
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [name, setName] = React.useState("");
  const [firm, setFirm] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const history = useHistory();

  const handleContactClose = () => {
    setContactOpen(false);
  };

  const handleLoginClose = () => {
    setLoginOpen(false);
  };

  const auth = (event) => {
    setLoading(true);
    event.preventDefault();
    let response = login(username, password);
    let token = "";
    response
      .then((res) => {
        setLoading(false);
        token = res.data.finalUser.token;
        Cookies.set("userInfo", token);
        if (
          res.data.finalUser.type === "ADMIN" ||
          res.data.finalUser.type === "FIRM"
        ) {
          history.push("/pipeline");
        } else {
          history.push("/company");
        }
      })
      .catch((err) => {
        console.log("error logging in");
        console.error(err);
        setError(true);
      });
  };

  const handleSubmission = (e) => {
    console.log(name, firm, email);
    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({
        "form-name": "contact",
        name: name,
        email: email,
        firm: firm,
        message: message,
      }),
    })
      .then(() => {
        handleContactClose();
        console.log("close contact");
      })
      .catch((error) => {
        console.log(error);
      });
    e.preventDefault();
  };

  return (
    <div style={{ marginTop: "35px" }} className="contact">
      <a
        href="#process"
        className="nav"
        style={{
          float: "left",
          marginLeft: "350px",
          marginTop: "5px",
          color: "white",
          fontSize: "25px",
          fontWeight: "lighter",
          cursor: "pointer",
        }}
      >
        Process
      </a>
      <a
        href="#team"
        className="nav"
        style={{
          float: "left",
          marginLeft: "20px",
          marginTop: "5px",
          color: "white",
          fontSize: "25px",
          fontWeight: "lighter",
          cursor: "pointer",
        }}
      >
        Team
      </a>
      <a
        href="#contact"
        className="nav"
        style={{
          float: "left",
          marginLeft: "20px",
          marginTop: "5px",
          color: "white",
          fontSize: "25px",
          fontWeight: "lighter",
          cursor: "pointer",
        }}
      >
        Contact
      </a>

      <Dialog
        open={contactOpen}
        onClose={handleContactClose}
        maxWidth="md"
        fullWidth={true}
        align="center"
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            align="center"
          >
            <img
              src={logo}
              align="center"
              style={{ width: 34, marginRight: 10 }}
              alt="logo"
            />
            <Typography style={{ fontSize: 25 }} align="center">
              {" "}
              Contact
            </Typography>
          </div>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmission}>
            <TextField
              label="Name"
              fullWidth={true}
              onChange={(event) => setName(event.target.value)}
            />
            <TextField
              label="Email address"
              type="email"
              fullWidth={true}
              onChange={(event) => setEmail(event.target.value)}
            />
            <TextField
              label="Firm"
              type="text"
              fullWidth={true}
              onChange={(event) => setFirm(event.target.value)}
            />
            <TextField
              label="Message"
              multiline
              rows={4}
              fullWidth={true}
              onChange={(event) => setMessage(event.target.value)}
            />
            <br />
            <Button
              variant="contained"
              type="submit"
              style={{
                marginRight: 10,
                marginTop: 10,
                backgroundColor: "#1E1E44",
                color: "white",
                textTransform: "none",
              }}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              onClick={handleContactClose}
              style={{ marginLeft: 10, marginTop: 10, textTransform: "none" }}
            >
              Cancel
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      <div>
        <div
          className="login"
          style={{ marginRight: "30px", textTransform: "none", fontSize: '21px', fontWeight: 'normal', backgroundColor: 'transparent', color: 'white', border: '.25px solid white', borderRadius: '5%', padding: 4, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
          onClick={() => {
            setLoginOpen(true);
          }}
        >
          Log In
        </div>
      </div>
      <Dialog
        open={loginOpen}
        onClose={handleLoginClose}
        maxWidth="sm"
        fullWidth={true}
        align="center"
      >
        <DialogTitle>
          <img src={logo} style={{ width: 50 }} />
          <Typography style={{ fontSize: 25 }}>Welcome to Velladore</Typography>
        </DialogTitle>
        <DialogContent align="center">
          <TextField
            align="left"
            variant="outlined"
            label="Email address"
            onChange={(event) => {
              setUsername(event.target.value);
            }}
            style={{ marginBottom: 10, width: "60%" }}
            error={error}
          />
          <br />
          <TextField
            align="left"
            variant="outlined"
            type="password"
            label="Password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            style={{ width: "60%" }}
            error={error}
          />
          <br />
          {loading && (
            <>
              {" "}
              <CircularProgress variant="indeterminate" /> <br />{" "}
            </>
          )}
          <Button
            variant="contained"
            type="submit"
            onClick={auth}
            style={{
              marginTop: 10,
              backgroundColor: "#1E1E44",
              color: "white",
              width: "60%",
              textTransform: "none",
            }}
          >
            Log In
          </Button>
          <br />
          <br />
          {/* <a href="#" style={{textDecoration: 'none'}}><Typography >Forgot your password?</Typography></a> */}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Login;
