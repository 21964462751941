import React from "react";
import Button from "@material-ui/core/Button";
import Login from "./Login.js";
import "../style/Homepage.css";
import "../style/Login.css";
import Register from "./Register";
import Burger from "./Burger/MobileLanding";
import Timeline from './Timeline';
import Team from './Team'
import Process from './Process';
import $ from 'jquery';
import Contact from './Contact'

const Homepage = (props) => {

  React.useEffect(() => {
    $(document).ready(function() {

      //window and animation items
      var animation_elements = $.find('.animation-element');
      var web_window = $(window);
    
      //check to see if any animation containers are currently in view
      function check_if_in_view() {
        //get current window information
        var window_height = web_window.height();
        var window_top_position = web_window.scrollTop();
        var window_bottom_position = (window_top_position + window_height);
    
        //iterate through elements to see if its in view
        $.each(animation_elements, function() {
    
          //get the element sinformation
          var element = $(this);
          var element_height = $(element).outerHeight();
          var element_top_position = $(element).offset().top;
          var element_bottom_position = (element_top_position + element_height);
    
          //check to see if this current container is visible (its viewable if it exists between the viewable space of the viewport)
          if ((element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position)) {
            element.addClass('in-view');
          } else {
            element.removeClass('in-view');
          }
        });
    
      }
    
      //on or scroll, detect elements in view
      $(window).on('scroll resize', function() {
          check_if_in_view()
        })
        //trigger our scroll event on initial load
      $(window).trigger('scroll');
    
    });

  }, [])
  return (
    <div id="home">
      <header className="heading">
        <a
          className="triangle-up-big fadeInUp animate-site-container offMobile"
          href="#"
        ></a>
        <a
          className="triangle-up-small fadeInUp animate-site-container offMobile"
          href="#"
        ></a>
        <a
          className="triangle-down fadeInDown animate-site-container offMobile"
          href="#"
        ></a>
        <Burger />
        <a
          href="#"
          className="fadeInDown animate-site-container headers heading_buttons"
        >
          Velladore
        </a>
        <Login className="login_icon" />
      </header>

      <div className="mountain" />
      <div id="landing-text">
        <div text-align="center;">
          <a className="triangle-up-big fadeInUp onMobile" href=""></a>
          <a className="triangle-up-small onMobile" href=""></a>
          <a className="triangle-down onMobile" href=""></a>
          <div id="landingText" className=" words">
            The diligence exchange platform <br id="text-break" /> for the
            lower-middle market
            <br />
          </div>
        </div>
        <br />
        <Register />
      </div>
        {/* <Timeline /> */}
        <a name="process" >
          <Process />
        </a>
        <a name="team">
        <div style={{paddingTop: 70}}>
          <Team />
          </div>
        </a>
        <a name="contact">
        <Contact />
        </a>

      {/* <div align="center">
        <Card style={{ maxWidth: 1200, marginTop: 30 }}>
          <CardHeader
            title="Our Mission:"
            style={{ backgroundColor: "#ececec" }}
          />
          <CardMedia>
            <p>
              Velladore was formed to bring efficiency in deal-flow to the
              deal-making process in the private equity markets.
            </p>
            <h2>
                                Stop wasting time on diligence that has already been done!
                            </h2>
          </CardMedia>
        </Card>
      </div>
      <div style={{ marginTop: 125, backgroundColor: "#f9f9f9" }}>

      </div> */}
    </div>
  );
};

export default Homepage;
