import React from "react";
import {
  Dialog,
  DialogContent,
  Card,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableRow,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core";
import { getDealActions } from "../../API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { extractFileType } from "./Upload";
import SignatureCanvas from "react-signature-canvas";

const dateConverter = (date) => {
    let dater = new Date(date);
    let finalizer = dater.getMonth() + 1 + "/";
    finalizer += dater.getDate() + "/";
    finalizer += dater.getFullYear();
    return finalizer;
  };



export default (props) => {
  const [dealActions, setDealActions] = React.useState(null);
  const [sigOpen, setSigOpen] = React.useState(false);

  let refer = React.createRef();

  function actionParser(action) {
    console.log(action)
    switch(action) {
        case 'Rate accept':
            return (<><p>You have been assigned a rate of .6% 
                <br/>If you choose to accept this rate, your firm will be paid .3% of the final purchase price of this deal. The other .3% will be paid to Velladore. <br/> <br/>
                Do you accept this rate?</p> <Button onClick={() => {setSigOpen(true)}}>Yes</Button> <Button onClick={() => {setSigOpen(false)}}>No</Button>
                </>)
        default:
            return (<p>no pending actions</p>)
    }
}

  React.useEffect(() => {
    if (props.dealId === null) {
      return;
    }
    getDealActions(props.dealId)
      .then((res) => {
        setDealActions(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props]);

  return (
          props.deals !== null &&
          <Dialog
          open={props.open}
          onClose={props.handleClose}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <h2>Pending Actions</h2>
                    {/* possible actions:
                                - rate acceptance
                                - deal closure
                                - need new document
                                */}
                    {!dealActions && (
                      <p>You have no pending actions at this time.</p>
                    )}
                    {dealActions && 
                        actionParser(dealActions['actionType'])
                    }
                  </CardContent>
                </Card>
                    <Accordion expanded={sigOpen} disabled={!sigOpen} style={{backgroundColor: 'white'}}>
                        <AccordionSummary style={{backgroundColor: 'white'}}>
                            
                        </AccordionSummary>
                            <AccordionDetails style={{display: 'grid'}}>
                                <Card style={{border: '1px solid black'}}>
                                    <CardContent>
                                    <SignatureCanvas

                                    ref={(ref) => {
                        refer = ref;
                      }}
                      penColor="black"
                      canvasProps={{ width: 800, height: 200, className: "sigCanvas", border: '1px solid black' }}
                    />
                                    </CardContent>
                                </Card>
                    <br/>
                    <Button onClick={() => {refer.clear()}}>
                        Clear
                    </Button>
                            </AccordionDetails>
                        </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <h2>Add additional files</h2> <br />
                    <Table>
                      {props.deals &&
                        props.deals[props.index]["originalName"].map(
                          (document, index) => (
                            <TableRow align="left">
                              <FontAwesomeIcon
                                style={{
                                  fontSize: 20,
                                  color: extractFileType(
                                    props.deals[props.index]["originalName"][index]
                                  )[1],
                                }}
                                icon={
                                  extractFileType(
                                    props.deals[props.index]["originalName"][index]
                                  )[0]
                                }
                              />
                              {" " +
                                props.deals[props.index]["originalName"][index]}
                            </TableRow>
                          )
                        )}
                    </Table>
                    <IconButton>
                      <FontAwesomeIcon icon={faPlusCircle} />
                    </IconButton>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <h2>Deal History:</h2>
                    <p style={{fontWeight: 'bold'}}>Upload: </p>
                            {dateConverter(props.deals[props.index]["uploadDate"])}
                    <br/>
                    <p style={{fontWeight: 'bold'}}>Rate assigned: </p>
                     01/04/2021
                    <br/>
                    <p style={{fontWeight: 'bold'}}> Rate accepted:</p>
                     01/06/2021
                    <br/>
                    <p style={{fontWeight: 'bold'}}> Deal closed:</p>
                     NA
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog> 
  );
};
