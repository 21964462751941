import Board from 'react-trello';
import React, { useEffect } from "react";
import { getDealsById, changeLanes } from '../../API';

const const_template = {
    lanes: [
    {
      id: 'downloaded',
      title: 'Downloaded',
      style: { backgroundColor: '#A6C9E9', color: 'black'},
      cards: []
    },
    {
      id: 'inReview',
      title: 'In Review',
      style: { backgroundColor: '#A6C9E9', color: 'black'},
      cards: []
    },
    {
      id: 'reviewed',
      title: 'Reviewed',
      style: { backgroundColor: '#A6C9E9', color: 'black'},
    //   label: reviewed.length,
      cards: [],
    }
  ]
}

const Kanban = (props) => {

    const [favorited, setFavorited] = React.useState(null);
    const [toReview, setToReview] = React.useState(null);
    const [reviewed, setReviewed] = React.useState(null);
    const [savedDeals, setSavedDeals] = React.useState(null);

    const [kanbanData, setKanbanData] = React.useState(null);

    useEffect(() => {
        async function fetchData() {
            if (!props.deals) {
                return
            }
    
            let downloads = getDealsById(props.deals['downloaded']);
            let inReviews = getDealsById(props.deals['inReview']);
            let revieweds = getDealsById(props.deals['reviewed']);

            Promise.all([downloads, inReviews, revieweds])
            .then(values => {
                console.log(values);
                let holder = populate_kanban_data(values[0].data, values[1].data, values[2].data);
                setKanbanData(holder);
            })
            .catch(err => {
                console.log(err);
            })
        }
        fetchData();

    }, [props.deals]);

    const populate_kanban_data = (favorited, toReview, reviewed) => {
        let template = const_template;
        template['lanes'][0].cards = [];
        template['lanes'][1].cards = [];
        template['lanes'][2].cards = [];

        favorited !== 'no data supplied' &&
        favorited.forEach(item => {
            console.log(item);
            template['lanes'][0].cards.push({
                id: item['_id'], title: item['companyName'], description: item['productDescription'], draggable: true, hideCardDeleteIcon: true
            })
        })

        toReview !== 'no data supplied' &&
        toReview.forEach(item => {
            template['lanes'][1].cards.push({
                id: item['_id'], title: item['companyName'], description: item['productDescription'], draggable: true, hideCardDeleteIcon: true
            })
        })

        reviewed !== 'no data supplied' &&
        reviewed.forEach(item => {
            template['lanes'][2].cards.push({
                id: item['_id'], title: item['companyName'], description: item['productDescription'], draggable: true, hideCardDeleteIcon: true
            })
        })
        console.log(template);
        return template
    }

    const handleLaneChange = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
        if (sourceLaneId !== targetLaneId) {
            changeLanes(sourceLaneId, targetLaneId, cardId);
        }
      }

    return (
            kanbanData && <Board style={{backgroundColor: 'white'}} data={kanbanData} hideCardDeleteIcon={true} cardDraggable={true} editLaneTitle={true} canAddLanes={false} handleDragEnd={handleLaneChange}/>
    )
}

export default Kanban;
