const industry_array = [
    'Energy',
    'Materials',
    'Consumer Discretionary',
    'Consumer Staples',
    'Health Care',
    'Financials',
    'Technology',
    'Communication',
    'Real Estate'
]

const state_array = ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming']

const location_array = [
    'West',
    'Northeast',
    'Midwest',
    'Southwest',
    'Southeast'
]

const revenue_marks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 50,
        label: '100k',
    },
    {
        value: 100,
        label: '500k',
    },
    {
        value: 150,
        label: '1m',
    },
    {
        value: 200,
        label: '2m',
    },
    {
        value: 250,
        label: '5m',
    },
    {
        value: 300,
        label: '20m',
    },
    {
        value: 350,
        label: '50m',
    },
    {
        value: 400,
        label: '100m',
    },
    {
        value: 450,
        label: '150m',
    },
    {
        value: 500,
        label: '250m+',
    }
]

const ebitda_marks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 50,
        label: '50k',
    },
    {
        value: 100,
        label: '100k',
    },
    {
        value: 150,
        label: '200k',
    },
    {
        value: 200,
        label: '1m',
    },
    {
        value: 250,
        label: '2m',
    },
    {
        value: 300,
        label: '5m',
    },
    {
        value: 350,
        label: '10m',
    },
    {
        value: 400,
        label: '20m',
    },
    {
        value: 450,
        label: '50m',
    },
    {
        value: 500,
        label: '100m+',
    }
]

export { industry_array, location_array, revenue_marks, ebitda_marks, state_array };
