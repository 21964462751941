import ReactHtmlParser from 'react-html-parser';

const privacy = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=Generator content="Microsoft Word 15 (filtered)">

</head>

<body lang=EN-US>

<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center'><b><span
style='font-size:20.0pt;line-height:115%'>Finders Fee Agreement</span></b></p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>Effective
Date ____/____/______</span></p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>The Company [Legal
Name], AKA (The Company)</span></p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>Located at [City][State]</span></p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>  [Address]</span></p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:115%'> [City],
[State] [Zip Code]</span></p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>&amp;</span></p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>Finders
Name<b> </b> [Legal Name],AKA (The Finder)</span></p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>A resident
of [City][State]</span></p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>Located at [Address]</span></p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:115%'> [City],
[State] [Zip Code]</span></p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>Phone Number [xxx-xxx-xxxx]</span></p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>Email
Address [email@.com]</span></p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>The Company
and the Finder agree to the following terms and conditions:</span></p>

<ol style='margin-top:0in' start=1 type=1>
 <li class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>The Finder
     fully understands and agrees that he or she will be conducting work as an
     independent contractor at his own expenses and risk in order to obtain
     information about various Real Estate properties and their address,
     owners name and contact information and to submit them to the Company as sales
     leads.</span></li>
 <li class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>If the
     lead is not known to the Company and also not listed with a Real Estate
     broker and the lead results in a purchase with either the Company or a
     Company that is assigned the lead. The Finder will receive a $750 Finders
     Fee in consideration for the profitable lead upon completion of the
     purchase transaction.</span></li>
 <li class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>The above
     mentioned finders fee will be due in full by the Company when the
     Purchase Agreement between the Company or Companys designated party and
     the owner of the property is executed.</span></li>
 <li class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>The Finder
     also is of the understanding that the Company is under no obligation to
     purchase from any leads that the Finder may introduce to the Company.</span></li>
 <li class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>Finally,
     the Finder fully understands and agrees that they will only provide
     information such as owner names, property addresses or contact information
     to the Company and the Finder will under no circumstances discuss the
     details of any such transaction nor provide any services that require a
     Real Estate License.</span></li>
</ol>

<p class=MsoNormal style='margin-left:.25in'><span style='font-size:12.0pt;
line-height:115%'>In witness of the above stated terms and conditions the
Finder and the Company execute this agreement as of the effective date stated
above and by signing below.</span></p>

<p class=MsoNormal align=center style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;text-align:center'><b>&nbsp;</b></p>

<p class=MsoNormal align=center style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;text-align:center'><b>Applicable
Law</b></p>

<p class=MsoNormal align=center style='margin-top:0in;margin-right:0in;
margin-bottom:0in;margin-left:.25in;margin-bottom:.0001pt;text-align:center'><b>&nbsp;</b></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;margin-bottom:.0001pt'>This contract shall be governed by the
laws of the State of <u>__________</u> in <u>__________</u> County and any
applicable Federal Law.</p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;margin-bottom:.0001pt'>&nbsp;</p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;margin-bottom:.0001pt'>&nbsp;</p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
margin-left:.25in;margin-bottom:.0001pt'>&nbsp;</p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt'><u>__________________________________________________________
Date____________</u> </p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt'>Signature of
Finder</p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt'>&nbsp;</p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt'>&nbsp;</p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt'>&nbsp;</p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt'><u>_____________________________________________________________________Date____________</u></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt'>Signature of
Company Representative</p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt'>&nbsp;</p>

<p class=MsoListParagraph>&nbsp;</p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>&nbsp;</span></p>

<p class=MsoNormal><span style='font-size:12.0pt;line-height:115%'>&nbsp;</span></p>

</div>

</body>

</html>
`;

const html = ReactHtmlParser(privacy);

export default html;

