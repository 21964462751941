import React from 'react';
import NumberFormatCustom from './Helpers/DollarFormat';
import { Grid, TextField, Button } from '@material-ui/core'

const DealClosureConfirm = (props) => {
    const [closingValue, setClosingValue] = React.useState('');
    const [link, setLink] = React.useState(null);

    const handleChange = (event) => {
        setClosingValue(event.target.value);
    }
    return (
        <>
            {props.open && 
            <Grid item xs={9} align="center">
            <h2>
                {props.title}
            </h2>
            <div>
                What was the closing cost on {props.title} ?
            </div>
            <TextField variant="outlined"
            InputProps={{
                inputComponent: NumberFormatCustom,
            }}
            value={closingValue}
            onChange={handleChange}
            />
            <div>
                Link to press release?
            </div>
            <TextField variant="outlined" />
            <br/>
            <br/>
            <Button variant="outlined" disabled={closingValue === '' || !link}>
                Submit
            </Button>
        </Grid>}
        </>

    )
}

export default DealClosureConfirm
