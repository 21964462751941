import Typography from "@material-ui/core/Typography";
import React, {useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from '@material-ui/core/TableBody';
import TableRow from "@material-ui/core/TableRow";
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import html from '../../static_pages/findersfee';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { getDeals, addSavedDeal, removeSavedDeal } from '../../API';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import StarIcon from '@material-ui/icons/Star';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { faCoffee, faCog, faDollarSign, faTshirt, faBatteryFull, faLaptop, faHouseUser, faPhone, faHospital, faCrop, faFile } from '@fortawesome/free-solid-svg-icons'
import DealPreview from './DealPreview';
import {revenue_ranges, ebitda_ranges} from './Helpers/FinancialRanges'

const headers = [
  "Project Name",
  "Interested",
  "EBITDA",
  "Revenue",
  "Industry",
  "Location",
  "Employee Count",
];

const numberWithCommas = (x) => {
  return ('$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

const iconReturner  = (item) => {
  let icon = null;

  switch (item['industry']) {
      case 'Financials':
          icon = faDollarSign
          break;
      case 'Materials':
          icon = faTshirt
          break;
      case 'Consumer Discretionary':
          icon = faDollarSign
          break;
      case 'Consumer Staples':
          icon = faCrop;
          break;
      case 'Health Care':
          icon = faHospital;
          break;        
      case 'Energy':
          icon = faBatteryFull;
          break;
      case 'Technology':
          icon = faLaptop;
          break;
      case 'Real Estate':
          icon = faHouseUser;
          break;
      case 'Communication':
          icon = faPhone;
          break;
      default:
          icon = null;
  }

  if (icon === null) {
      return 'loading';
  }
  return (
      <>
          <FontAwesomeIcon icon={icon}/> {' '} {item['industry']}
      </>
  )
}

const Deals = (props) => {

    const history = useHistory();

  const addSaved = (dealID, index) => {
    addSavedDeal(dealID)
    .then(res => {
      let holder = deals;
      holder[index].saved = true;
      setDeals([...holder]);
    })
    .catch(err => {
      console.log(err);
    })
  }

  const removeSaved = (dealID, index) => {
    removeSavedDeal(dealID)
    .then(res => {
      let holder = deals;
      holder[index].saved = false;
      setDeals([...holder]);
    })
    .catch(err => {
      console.log(err);
    })
  }

  const [preview, setPreview] = React.useState(null);
  const [graphData, setGraphData] = React.useState(null);
  const [deals, setDeals] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
      let query = new URLSearchParams(window.location.search);
    if (!query.has('rmin')) {
        return;
    }
    // let { revenue, ebitda, industry, location, favorited } = query;
    let revenue = [query.get('rmin'), query.get('rmax')];
    let ebitda = [query.get('emin'), query.get('emax')];
    let favorited = query.get('favorited');
    let location = [];
    let industry = [];
    getDeals(revenue, ebitda, industry, location, favorited)
    .then(res => {
        console.log(res.data);
        if (res.data.length === 0) {
            alert('search parameters too narrow. Please widen parameters');
        }
        else {
            setDeals(res.data);
            console.log('success');
        }
    })
    .catch(err => {
        console.error(err);
    })
    // setDeals(props.deals)
    // if (!props.deals || preview === null) {
    //   return;
    // }
    // let info = []
    // info.push({
    //   'year': 2018,
    //   'ebitda': deals[preview]['ebitda_2018'],
    //   'revenue': deals[preview]['revenue_2018']
    // })
    // info.push({
    //   'year': 2019,
    //   'ebitda': deals[preview]['ebitda_2019'],
    //   'revenue': deals[preview]['revenue_2019']
    // })
    // info.push({
    //   'year': 2020,
    //   'ebitda': deals[preview]['ebitda_2020'],
    //   'revenue': deals[preview]['revenue_2020']
    // })
    // setGraphData(info);

  }, [window.location.search])

  const setClose = () => {
    setOpen(false);
  }

  const toggleLeft = () => {
    console.log(preview);
    setPreview(preview - 1);
  }
  const toggleRight = () => {
    console.log(preview);
    setPreview(preview + 1);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.handlePaginator(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSetPreview = (index) => {
    setOpen(true);
    setPreview(index);
    // console.log(deals[preview])
    let info = []
    info.push({
      'year': 2018,
      'ebitda': deals[index]['ebitda_2018'],
      'revenue': deals[index]['revenue_2018']
    })
    info.push({
      'year': 2019,
      'ebitda': deals[index]['ebitda_2019'],
      'revenue': deals[index]['revenue_2019']
    })
    info.push({
      'year': 2020,
      'ebitda': deals[index]['ebitda_2020'],
      'revenue': deals[index]['revenue_2020']
    })
    setGraphData(info);
  }

  return (
      <>
    <div >
    {deals === null && <Typography style={{marginLeft: 'auto', marginRight: 'auto'}}>Select the filter above to perform a search</Typography>}
      {deals !== null &&
      <>
      <Table size="small" style={{ maxWidth: '100%' }}>
        <TableHead>
          <TableRow style={{ backgroundColor: "#1E1E44", height: 55}} hover={true}>
            <TableCell align="left" style={{ fontWeight: 'bold', color: 'white' }}>{headers[0]}</TableCell>
            <TableCell align="left" style={{ fontWeight: 'bold', color: 'white' }}>{headers[2]}</TableCell>
            <TableCell align="left" style={{ fontWeight: 'bold', color: 'white' }}>{headers[3]}</TableCell>
            <TableCell align="left" style={{ fontWeight: 'bold', color: 'white' }}>{headers[4]}</TableCell>
            <TableCell align="left" style={{ fontWeight: 'bold', color: 'white' }}>{'Starred'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deals.slice(page * props.paginator, page *  props.paginator + props.paginator).map((item, index) => (
            <TableRow hover={true} style={{cursor: 'pointer'}} key={item['_id']} >
              {/* <TableCell> <Checkbox color="primary" checked={item[0]} /> </TableCell> */}
              <TableCell onClick={() => {history.push(`/explore/deal/${item['_id']}`)}}>Project {item['alias']}</TableCell>
              <TableCell onClick={() => {history.push(`/explore/deal/${item['_id']}`)}}> {ebitda_ranges(item['ebitda_2020'])}</TableCell>
              <TableCell onClick={() => {history.push(`/explore/deal/${item['_id']}`)}}>{revenue_ranges(item['revenue_2020'])}</TableCell>
              <TableCell onClick={() => {history.push(`/explore/deal/${item['_id']}`)}}> {iconReturner(item)}</TableCell>
              <TableCell>
                {deals[index].saved ? <StarIcon color='primary' onClick={() => {removeSaved(item['_id'], index)}}/> : <StarBorderIcon color="primary" onClick={() => {addSaved(item['_id'], index)}} /> }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
      count={deals.length}
      rowsPerPage={props.paginator}
      page={page}
      rowsPerPageOptions={[5, 10, 20]}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      </>
      }
      <DealPreview addSaved={addSaved} removeSaved={removeSaved} toggleRight={toggleRight} toggleLeft={toggleLeft} open={open} preview={preview} graphData={graphData} deals={deals} setClose={setClose} user={props.user}/>
    </div>
</>
  );
};

export { Deals, iconReturner };
