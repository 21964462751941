import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Login from "../Login.js";
import "../../style/Homepage.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography, Tooltip, Fab } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MailIcon from "@material-ui/icons/Mail";
import InboxIcon from "@material-ui/icons/Inbox";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import CssBaseline from "@material-ui/core/CssBaseline";
import HomeIcon from "@material-ui/icons/Home";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import ForwardIcon from "@material-ui/icons/Forward";
import UploadIcon from "@material-ui/icons/Backup";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import FolderIcon from "@material-ui/icons/PermMedia";
import HelpIcon from "@material-ui/icons/HelpOutline";
import ScatterPlotIcon from "@material-ui/icons/ScatterPlot";
import Dashboard from "./Dashboard";
import whiteLogo from "../../static_pages/white_logo.png";
import Download from "./Explore";
import { Upload } from "./Upload";
import Selector from "./UploadSelector";
import Help from "./Help";
import clsx from "clsx";
import Account from "./Account";
import { useHistory, Link, BrowserRouter as Router, Redirect, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import Archive from "./Archive";
import logo from "../../static_pages/grey_logo.png";
import profiler from "../../static_pages/navy_logo.png";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { getProfileInfo } from "../../API";
import { getUploaded, getDownloaded, getNotifications } from "../../API";
import LogoutConfirmation from "./LogoutConfirmation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShareAlt,
  faCompass,
  faCloudUploadAlt,
  faAngleDoubleRight,
  faStar,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons";
import NotificationsBar from './NotificationsBar'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px + 5px)`,
      marginLeft: `calc(${drawerWidth}px - 6px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const side_navigation = [
  "Upload",
  "Explore",
  "my-deals",
  "Pipeline",
  "Help",
];
const side_navigation_company = [ "Upload", "Dashboard", "Help"];

const Profile = (props) => {

  let locay = useLocation().pathname;

  console.log(locay);

  const history = useHistory();

  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [navigator, setNavigator] = React.useState(props.page);
  const [searched, setSearched] = React.useState(null);
  const [uploadHidden, setUploadHidden] = React.useState(true);
  const [profile, setProfile] = React.useState(null);
  const [uploaded, setUploaded] = React.useState(null);
  const [downloaded, setDownloaded] = React.useState(null);
  const [logoutOpen, setLogoutOpen] = React.useState(false);
  const [paginator, setPaginator] = React.useState(5);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const [unread, setUnread] = React.useState(0);
  const [myDealsParched, setMyDealsParched] = React.useState(false);

  const setDealsParched = () => {
    setMyDealsParched(true);
  }

  const quenchMyDeals = () => {
    getDownloaded()
    .then((res) => {
      setDownloaded(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
    setMyDealsParched(false);
  }

  const handleCloseLogout = () => {
    setLogoutOpen(false);
  };

  const handlePaginator = (num) => {
    setPaginator(num);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
      setDrawerOpen(false);
  };

  const editRead = (index) => {
    let editNotifications = [...notifications];
    editNotifications[index].read = true;
    setNotifications(editNotifications);
    setUnread(notifications.filter(item => item.read === false).length);
  }

  React.useEffect(() => {
    getProfileInfo()
      .then((account) => {
        console.log(account.data.data);
        setProfile(account.data.data);
        props.handleProfile(account.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
    getUploaded()
      .then((res) => {
        setUploaded(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getDownloaded()
      .then((res) => {
        setDownloaded(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
      getNotifications()
      .then((res) => {
        setNotifications(res.data);
        setUnread(res.data.filter(item => item.read === false).length);
      })
      .catch((err) => {
        console.log(err)
      });
  }, []);

  const replenishUploaded = () => {
    console.log('replenishing uploaded')
    getUploaded()
    .then((res) => {
      setUploaded(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const downloadSearch = (data) => {
    setSearched(data);
  };

  const open = Boolean(anchorEl);

  function icon_returner(text) {
    switch (text) {
      case "Dashboard":
        return <HomeIcon />;
      case "Pipeline":
        return (
          <FontAwesomeIcon icon={faAngleDoubleRight} style={{ fontSize: 23 }} />
        );
      case "Upload":
        return (
          <FontAwesomeIcon icon={faCloudUploadAlt} style={{ fontSize: 23 }} />
        );
      case "Explore":
        return <FontAwesomeIcon icon={faCompass} style={{ fontSize: 23 }} />;
      case "Documents":
        return <FolderIcon />;
      case "my-deals":
        return <FontAwesomeIcon icon={faBookmark} style={{ fontSize: 23 }} />;
      case "Help":
        return <HelpIcon />;
    }
  }

  function velladore_check(text) {
    if (text === "Velladore") {
      return <Divider style={{ color: "white" }} />;
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = () => {
    console.log("logging out");
    Cookies.remove("userInfo");
    history.push("/home");
  };

  const render_firm = () =>
    <div style={{paddingTop: 25}}>
    {side_navigation.map((text, index) => (
      <Link to={text === 'My Deals' ? 'my-deals' : `/${text.toLowerCase()}`} style={{textDecoration: 'none'}}>
      <ListItem
        key={text}
        style={{ backgroundColor: locay.includes(`/${text.toLowerCase()}`) ? "white" : "#1E1E44" }}
      >
        <ListItemIcon style={{ color: locay.includes(`/${text.toLowerCase()}`) ? "black" : "white" }}>
          {icon_returner(text)}
        </ListItemIcon>
        <ListItemText>
          <Typography
            style={{
              color: locay.includes(`/${text.toLowerCase()}`) ? "black" : "white",
              fontSize: 20,
            }}
          >
            {text === 'my-deals' ? 'My Deals' : text}
          </Typography>
        </ListItemText>
      </ListItem>
      </Link>
    ))
          }
    </div>

  const render_company = () => {
    return side_navigation_company.map((text, index) => (
      <ListItem
        button
        key={text}
        onClick={() => {
          setNavigator(text);
        }}
        style={{ backgroundColor: locay.includes(`/${text.toLowerCase()}`) ? "white" : "#1E1E44" }}
      >
        <ListItemIcon style={{ color: locay.includes(`/${text.toLowerCase()}`) ? "black" : "white" }}>
          {icon_returner(text)}
        </ListItemIcon>
        <ListItemText>
          <Typography
            style={{
              color: locay.includes(`/${text.toLowerCase()}`) ? "black" : "white",
              fontSize: 20,
            }}
          >
            {text}
          </Typography>
        </ListItemText>
      </ListItem>
    ));
  };

  const drawer = (
    <div style={{ backgroundColor: "#1E1E44", height: "100%" }}>
      <Divider />
      <List style={{paddingTop: 4}}>
        <ListItem align="center" style={{paddingTop: 0}}>
          {/* <IconButton>
                  <ListItemIcon>
                    <MenuIcon style={{color: 'white', fontSize: 30}}/>
                  </ListItemIcon>
                </IconButton> */}
          <ListItemIcon>
            <img src={whiteLogo} style={{ height: "30px" }} alt="" />
          </ListItemIcon>
          <ListItemText align="left">
            <Typography
              style={{
                color: "white",
                fontSize: 32,
                verticalAlign: "text-bottom",
              }}
            >
              Velladore
            </Typography>
          </ListItemText>
        </ListItem>
        <Divider style={{ height: ".5px", color: "white" }} />
        {props.company && render_company()}
        {!props.company && render_firm()}
      </List>
      <div
        style={{
          position: "absolute",
          color: "white",
          bottom: 20,
          marginLeft: "10%",
          marginRight: "10%",
          width: "80%",
        }}
        align="center"
      >
        <div
          style={{
            borderTop: ".5px solid grey",
            marginBottom: 15,
            width: "100%",
          }}
        />
        <img
          className="logo"
          alt="logo"
          src={logo}
          style={{ marginBottom: 10 }}
        />
        <div align="center" style={{ color: "grey" }}>
          &copy; 2021 All Rights Reserved <br />
          Velladore LLC
        </div>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        className={classes.appBar}
        style={{ backgroundColor: "#1E1E44", minHeight: 60 }}
      >
        <Toolbar>
          <Link
            style={{
              height: 43,
              width: 43,
              borderRadius: 50,
              border: "1px solid white",
              overflow: "hidden",
              backgroundColor: "grey",
              position: "relative",
              align: "left",
              cursor: "pointer",
            }}
            to="/account"
            align="center"
          >
            <Tooltip title="Account Info">
              <img
                alt=""
                src={profiler}
                style={{ width: 27, height: 27, margin: 5 }}
              />
            </Tooltip>
          </Link>
          {profile && (
            <Link
            to="/account"
              style={{
                fontSize: 16,
                marginLeft: 10,
                marginTop: "8px",
                cursor: "pointer",
                textDecoration: 'none',
                color: 'white'
              }}
              align="center"
            >
              Welcome, {profile.name.split(" ")[0]}
            </Link>
          )}

          {/* <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    style={{}}
                >
                  </div>
                    {/* <AccountCircle style={{ fontSize: 30}} /> */}
          {/* </IconButton > */}
          {/* <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                </Menu>                     */}
          {/* <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => {console.log('clicked notifications')}}
                    color="inherit"
                    style={{position: 'relative'}}
                >
                    <Badge badgeContent={9} color="secondary">
                        <NotificationsIcon style={{ fontSize: 30}}/>
                    </Badge>
                </IconButton>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => {console.log('clicked notifications')}}
                    color="inherit"
                    style={{position: 'relative'}}
                >
                    <Badge badgeContent={12} color="secondary">
                        <MailIcon style={{ fontSize: 30}}/>
                    </Badge>
                </IconButton> */}
          <div style={{ position: "absolute", right: 30 }}>
          <IconButton onClick={handleDrawerOpen}>
              <Badge badgeContent={unread} color="secondary">
                <NotificationsIcon style={{ fontSize: 30, color: "white" }} />
              </Badge>
            </IconButton>
            <NotificationsBar drawerOpen={drawerOpen} handleDrawerClose={handleDrawerClose} notifications={notifications} editRead={editRead} />
            <Tooltip title="Log Out">
              <IconButton
                onClick={() => {
                  setLogoutOpen(true);
                }}
                color="inherit"
              >
                <ExitToAppIcon style={{ fontSize: 30 }} />
              </IconButton>
            </Tooltip>


            <LogoutConfirmation
              open={logoutOpen}
              logout={logout}
              closer={handleCloseLogout}
            />
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      {/* <main className={classes.content}>
        <div className={classes.toolbar} />
        {navigator === "Pipeline" && <Dashboard />}
        {navigator === "Explore" && (
          <Download
            paginator={paginator}
            handlePaginator={handlePaginator}
            dealSetter={downloadSearch}
            dealsSearched={searched}
            user={profile}
          />
        )}
        {navigator === "Upload" && uploadHidden && (
          <Selector
            paginator={paginator}
            handlePaginator={handlePaginator}
            uploader={setUploadHidden}
            uploadedDeals={uploaded}
            replenishUploaded={replenishUploaded}
          />
        )}
        {navigator === "Upload" && !uploadHidden && (
          <Upload goback={setUploadHidden} />
        )}
        {navigator === "My Deals" && <MyDeals quenchDeals={quenchMyDeals} parched={myDealsParched} firmCity={profile.city} coordinates={profile.firmCoordinates} />}
        {navigator === "Help" && <Help />}
        {navigator === "Account" && <Account user={profile} />}
      </main> */}
    </div>
  );
};
export default Profile;
