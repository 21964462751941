import React from "react";
import Card from "@material-ui/core/Card";
import { CardContent, Grid, IconButton, TextField, Slider, Chip, Select } from "@material-ui/core/";
import Paper from "@material-ui/core/Paper";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import profile from "../../static_pages/navy_logo.png";
import { editInvestmentCriteria, changeContactProfile, changeIndAndLoc } from "../../API";
import CreateIcon from "@material-ui/icons/Create";
import {
  industry_array,
  location_array,
  revenue_marks,
  ebitda_marks,
} from "../../Models/Industry";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";


function valuetext(value) {
  return `$${value}`;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Account = (props) => {
  const classes = useStyles();

  const [userInfo, setUserInfo] = React.useState(props.user);
  const [dealInfoOpen, setDealInfoOpen] = React.useState(false);
  const [contactInfoOpen, setContactInfoOpen] = React.useState(false);
  const [billingInfoOpen, setBillingInfoOpen] = React.useState(false);
  const [ebitda, setEbitda] = React.useState([props.user['ebitdaMin'], props.user['ebitdaMax']]);
  const [revenue, setRevenue] = React.useState([props.user['revenueMin'], props.user['revenueMax']]);
  const [name, setName] = React.useState(props.user['name']);
  const [phone, setPhone] = React.useState(props.user['phone']);
  const [title, setTitle] = React.useState(props.user['title']);
  const [industry, setIndustry] = React.useState(props.user['industry']);
  const [location, setLocation] = React.useState(props.user['location']);
  const [editIndustryAndLocation, setEditIndustryAndLocation] = React.useState(false)

  const handleIndustry = (event) => {
    setIndustry(event.target.value);
  };

  const handleLocation = (event) => {
    setLocation(event.target.value);
  };
  // const [email, setEmail] = React.useState(props.user['email']);


  const handleRevenue = (event, newValue) => {
    setRevenue(newValue);
  };

  const handleEbitda = (event, newValue) => {
    setEbitda(newValue);
  };

  const fireEditDealPreference = () => {
    editInvestmentCriteria(revenue, ebitda)
    .then(() => {
      setDealInfoOpen(false);
    })
    .catch(err => {
      console.log(err);
    })
  }

  const fireEditIndustryAndLocation = () => {
    changeIndAndLoc(industry, location)
    .then(() => {
      setEditIndustryAndLocation(false);
    })
    .catch(err => {
      console.log(err);
    })
  }

  const fireEditProfile = () => {
    changeContactProfile({name: name, phone: phone, title: title})
    .then(res => {
      console.log(res)
      setContactInfoOpen(false);
      let newUser = {};
      newUser.firm = userInfo.firm;
      newUser.city = userInfo.city;
      newUser.state = userInfo.state;
      newUser.email = userInfo.email;
      newUser['phone'] = phone;
      newUser['name'] = name;
      newUser['title'] = title;
      setUserInfo(newUser);
    })
    .catch(err => {
      console.log(err);
    })
  }

  const phoneNumberConverter = (number) => {
    let stringer = number.toString();
    let returner = "";
    returner += stringer.substring(0, 3);
    returner += "-";
    returner += stringer.substring(3, 6);
    returner += "-";
    returner += stringer.substring(6, 10);
    return returner;
  };

  const editDealInfo = () => {
    setDealInfoOpen(true);
  };

  const editContactInfo = () => {
    setContactInfoOpen(true);
  };

  const editBillingInfo = () => {
    setBillingInfoOpen(true);
  };

  const closeDealInfo = () => {
    setDealInfoOpen(false);
  };

  const closeContactInfo = () => {
    setContactInfoOpen(false);
  };

  const closeBillingInfo = () => {
    setBillingInfoOpen(false);
  };

  const closeIndustryInfo = () => {
    setEditIndustryAndLocation(false);
  }

  const handleName = (event) => {
    setName(event.target.value);
  }
  // const handleEmail = (event) => {
  //   setEmail(event.target.value);
  // }
  const handlePhone = (event) => {
    setPhone(event.target.value);
  }

  const handleTitle = (event) => {
    setTitle(event.target.value);
  }


  return (
    <>
      <Paper>
        <Card style={{ maxHeight: 300, width: "100%" }}>
          <CardContent align="center">
            <div
              style={{
                height: 90,
                width: 90,
                borderRadius: 50,
                border: "2px solid black",
                overflow: "hidden",
                backgroundColor: "grey",
              }}
              align="center"
            >
              <img
                alt="profile"
                src={profile}
                style={{ width: 62, height: 62, margin: 5 }}
              />
            </div>

            <Typography gutterBottom variant="h5" component="h2">
              {userInfo.firm}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {`${userInfo.city}, ${userInfo.state}`}
            </Typography>
          </CardContent>
          {/* <CardActions style={{ borderTop: ".5px solid grey" }}>
          <Button style={{textDecoration: 'none'}}>Change image</Button>
          <Button style={{textDecoration: 'none'}}>Remove image</Button>
        </CardActions> */}
        </Card>
        <Grid container>
          <Grid item xs={6}>
            <Card style={{ maxHeight: 600, width: "100%", marginTop: 20, paddingLeft: 12, paddingRight: 15 }}>
              <CardContent>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: "auto" }} />
                  <IconButton onClick={editDealInfo}>
                    <CreateIcon />
                  </IconButton>
                </div>
                  <>
                    <div style={{ display: "flex" }}>
                    <h2 style={{fontWeight: 'normal'}}>

                        Revenue:
                      </h2>
                    </div>
                    <Slider
                      style={{ marginTop: 50}}
                      value={revenue}
                      onChange={handleRevenue}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      valueLabelDisplay="off"
                      valueLabelFormat={valuetext}
                      marks={revenue_marks}
                      step={null}
                      disabled={!dealInfoOpen}
                      max={500}
                      min={0}
                    />
                    <h2 style={{fontWeight: 'normal'}}>

                        EBITDA:
                      </h2>
                      <Slider
                      style={{ marginTop: 50}}
                      value={ebitda}
                      onChange={handleEbitda}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      valueLabelDisplay="off"
                      valueLabelFormat={valuetext}
                      marks={ebitda_marks}
                      step={null}
                      disabled={!dealInfoOpen}
                      max={500}
                      min={0}
                    />
                    {/* <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >                        Industries:
                      </Typography>
                      <TextField variant="outlined"/>
                    <div style={{ display: "flex" }}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        Regions:
                      </Typography>
                      <TextField variant="outlined"/>
                    </div> */}
                  </>
              </CardContent>
              {dealInfoOpen && (
                <CardActions>
                  <Button style={{ textTransform: "none" }} onClick={fireEditDealPreference}>Save</Button>
                  <Button
                    style={{ textTransform: "none" }}
                    onClick={closeDealInfo}
                  >
                    Cancel
                  </Button>
                </CardActions>
              )}
            </Card>
            <Card style={{marginTop: 10}}>
              <CardContent>
              <div style={{ display: "flex" }}>
                  <Typography style={{ flex: "auto" }}>
                    <h2 style={{fontWeight: 'normal'}}>
                    Industry
                    </h2>
                  </Typography>
                  <div style={{ flex: "auto" }} />
                  <IconButton onClick={() => {setEditIndustryAndLocation(true)}}>
                    <CreateIcon />
                  </IconButton>
                </div>
          <Select
            disabled={!editIndustryAndLocation}
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            value={industry}
            onChange={handleIndustry}
            // input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
            style={{width: '100%'}}
          >
            {industry_array.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <br />
          <h2 style={{fontWeight: 'normal'}}>Location</h2>
          <Select
            disabled={!editIndustryAndLocation}
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            value={location}
            onChange={handleLocation}
            // input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
            style={{width: '100%'}}
          >
            {location_array.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
              </CardContent>
              {editIndustryAndLocation && (
                <CardActions>
                  <Button style={{ textTransform: "none" }} onClick={fireEditIndustryAndLocation}>Save</Button>
                  <Button
                    style={{ textTransform: "none" }}
                    onClick={closeIndustryInfo}
                  >
                    Cancel
                  </Button>
                </CardActions>
              )}
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card style={{ maxHeight: 600, width: "100%", marginTop: 20 }}>
              <CardContent>
                {!contactInfoOpen && 
                <>
                <div style={{ display: "flex" }}>
                <h2 style={{fontWeight: 'normal'}}>Contact Information</h2>
                  <div style={{ flex: "auto" }} />
                  <IconButton onClick={editContactInfo}>
                    <CreateIcon />
                  </IconButton>
                </div>
                <Typography variant="body2" color="textSecondary" component="p">
                  email address: {userInfo.email}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  phone number:{" "}
                  {userInfo.phone !== null
                    ? phoneNumberConverter(userInfo.phone)
                    : ""}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  main representative: {userInfo.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  representative title: {userInfo.title}
                </Typography>
                </>
                }
                {contactInfoOpen && 
                <>
                {/* <TextField
                label="email address"
                value={email}
                onChange={newby => {handleEmail(newby)}}
                style={{marginBottom: 20}}/>
                <br/> */}
                <TextField
                label="phone number"
                value={phone}
                onChange={newby => {handlePhone(newby)}}
                style={{marginBottom: 20}}/>
                <br/>
                <TextField
                label="main representative"
                value={name}
                onChange={newby => {handleName(newby)}}/>
                <TextField
                label="representative title"
                value={title}
                onChange={newby => {handleTitle(newby)}}/>
                </>
                }

              </CardContent>
              {contactInfoOpen && (
                <CardActions>
                  <Button style={{ textTransform: "none" }} onClick={fireEditProfile}>Save</Button>
                  <Button
                    style={{ textTransform: "none" }}
                    onClick={closeContactInfo}
                  >
                    Cancel
                  </Button>
                </CardActions>
              )}
            </Card>
            <Card style={{ maxHeight: 600, width: "100%", marginTop: 20 }}>
              <CardContent>
                <div style={{ display: "flex" }}>
                <h2 style={{fontWeight: 'normal'}}>
Billing Information</h2>
                  <div style={{ flex: "auto" }} />
                  <IconButton onClick={editBillingInfo}>
                    <CreateIcon />
                  </IconButton>
                </div>
                <Typography variant="body2" color="textSecondary" component="p">
                  First Name:
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Last Name:
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Card Number:
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Expiration:
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Zip Code:
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  CVV:
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Country/Region:
                </Typography>
              </CardContent>
              {billingInfoOpen && (
                <CardActions>
                  <Button style={{ textTransform: "none" }}>Save</Button>
                  <Button
                    style={{ textTransform: "none" }}
                    onClick={closeBillingInfo}
                  >
                    Cancel
                  </Button>
                </CardActions>
              )}
              {/* <CardActions>
            <Button variant="outlined" color="primary">
              edit information
            </Button>
          </CardActions> */}
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
};

export default Account;
