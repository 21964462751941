import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PublishIcon from '@material-ui/icons/Publish';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function CustomizedTimeline() {
  const classes = useStyles();

  return (
    <Timeline align="alternate" style={{marginTop: 200}}>
        <h1 align="center">
            How it works:
        </h1>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary">
          <ImportContactsIcon/>
          </TimelineDot>
          <TimelineConnector style={{minHeight: '150px'}}/>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper} style={{marginLeft: 300}}>
            <Typography variant="h6" component="h1">
              Firm A performs diligence on a company
            </Typography>
            <Typography>Typically most expensive aspect in the process</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="secondary">
            <FindInPageIcon/>
          </TimelineDot>
          <TimelineConnector style={{minHeight: '150px'}}/>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper} style={{marginRight: 300}}  align="left">
            <Typography variant="h6" component="h1">
              Firm B begins deal sourcing
            </Typography>
            <Typography>blaaahhh</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <PublishIcon/>
          </TimelineDot>
          <TimelineConnector style={{minHeight: '150px'}} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper} style={{marginLeft: 300}}>
            <Typography variant="h6" component="h1" style={{display: 'flex'}}>
              Firm A passes on the deal and uploads to Velladore
            </Typography>
            <Typography>As easy as filling out 6 boxes and uploading files</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="secondary">
            <InsertChartIcon />
          </TimelineDot>
          <TimelineConnector style={{minHeight: '150px'}} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper} style={{marginRight: 300}}  align="left">
            <Typography variant="h6" component="h1">
              Firm B comes across summary of Firm A's diligence
            </Typography>
            <Typography>interested, they request more information</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <DoneAllIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper} style={{marginLeft: 300}}>
            <Typography variant="h6" component="h1">
              Firm B closes deal using Firm A's diligence
            </Typography>
            <Typography>Firm A receives finder's fee while Firm B saves on sourcing and diligence costs</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}