import Typography from "@material-ui/core/Typography";
import React from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { DropzoneArea } from "material-ui-dropzone";
import Snackbar from "@material-ui/core/Snackbar";
import Card from "@material-ui/core/Card";
import MuiAlert from "@material-ui/lab/Alert";
import { uploadDeal } from "../../API";
import NumberFormatCustom from "./Helpers/DollarFormat";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {
  AccordionSummary,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Checkbox,
  FormGroup,
  Icon,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from "@material-ui/core/Chip";
import { industry_array, state_array } from "../../Models/Industry";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
  faFileExcel,
  faFile,
  faCheckCircle,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const extractFileType = (name) => {
  let sides = name.split(".");
  let type = sides[1];
  let icon = faFile;
  let color = 'black'
  switch (type) {
    case "pptx":
      icon = faFilePowerpoint;
      color = '#D04423';
      break;
    case "doc":
      icon = faFileWord;
      color = 'blue'
      break;
    case "docx":
      icon = faFileWord;
      color = 'blue'
      break;
    case "xlsx":
      icon = faFileExcel;
      color = 'green'
      break;
    case "pdf":
      icon = faFilePdf;
      color = 'red'
      break;
    case "jpg":
        icon = faFile;
        color = '#2C404D'
        break;
    case "jpeg":
        icon = faFile;
        color = '#2C404D'
        break;
    default:
      icon = faFile;
      break;
  }
  return [icon, color];
};

const Upload = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [revenueError, setRevenueError] = React.useState([false, false]);
  const [revenue2018, setRevenue2018] = React.useState("");
  const [revenue2019, setRevenue2019] = React.useState("");
  const [revenue2020, setRevenue2020] = React.useState("");
  const [EBITDA2018, setEBITDA2018] = React.useState("");
  const [EBITDA2019, setEBITDA2019] = React.useState("");
  const [EBITDA2020, setEBITDA2020] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [url, setURL] = React.useState("");
  const [industry, setIndustry] = React.useState("");
  const [productDescription, setProductDescription] = React.useState(null);
  const [contactName, setContactName] = React.useState(null);
  const [contactEmail, setContactEmail] = React.useState(null);
  const [locationState, setLocationState] = React.useState(null);
  const [locationAddress, setLocationAddress] = React.useState(null);
  const [locationAddress2, setLocationAddress2] = React.useState(null);
  const [locationZip, setLocationZip] = React.useState(null);
  const [locationCity, setLocationCity] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [toaster, setToaster] = React.useState(false);
  const [successToaster, setSuccessToaster] = React.useState(false);
  const [toasterValue, setToasterValue] = React.useState("");
  const [fileIcon, setFileIcon] = React.useState(null);
  const [checked1, setChecked1] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [checked3, setChecked3] = React.useState(false);
  const [checked4, setChecked4] = React.useState(false);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToaster(false);
  };

  const handleLocation = (newVal) => {
    setLocationState(newVal.target.value);
  };

  const handleIndustry = (newVal) => {
    setIndustry(newVal.target.value);
  };

  const handleFileUpload = (newVal) => {
    if (!newVal.target.files[0]) {
      return;
    }
    let existing_files = [];
    if (file) {
      existing_files = file;
    }
    let existing_icons = [];
    if (fileIcon) {
      existing_icons = fileIcon;
    }
    setFile([...existing_files, newVal.target.files[0]]);
    setFileIcon([
      ...existing_icons,
      extractFileType(newVal.target.files[0].name)[0],
    ]);
  };

  const handleFileDelete = (index) => {
    let existing_files = file;
    existing_files.splice(index, 1);

    let existing_icons = fileIcon;
    existing_icons.splice(index, 1);

    if (existing_files.length === 0) {
      setFile(null);
      setFileIcon(null);
    } else {
      setFile([...existing_files]);
      setFileIcon([...existing_icons]);
    }
  };

  const checkComplete = () => {
    console.log("checking completion");
    if (
      revenue2018 === "" ||
      revenue2019 === "" ||
      revenue2020 === "" ||
      EBITDA2018 === "" ||
      EBITDA2019 === "" ||
      EBITDA2020 === "" ||
      !companyName ||
      !url ||
      !locationState ||
      !locationAddress ||
      !industry ||
      !productDescription ||
      !contactEmail ||
      !contactName 
    )
      return true;
    return false;
  };

  const resetFields = () => {
    console.log("fields reset");
    setRevenue2018("");
    setRevenue2019("");
    setRevenue2020("");
    setEBITDA2018("");
    setEBITDA2019("");
    setEBITDA2020("");
    setContactEmail("");
    setContactName("");
    setIndustry("");
    setLocationAddress("");
    setLocationAddress2("");
    setLocationState("");
    setLocationZip("")
    setFile(null);
    setLocationCity("");
    setProductDescription("");
    setCompanyName("");
    setURL("");
    setFileIcon(null);
  };

  const fireUploads = () => {
    // if (!file) {
    //   setToasterValue("Please select a file to upload");
    //   setToaster(true);
    //   return;
    // }
    // if (revenue2018 === "" || revenue2019 === "" || revenue2020 === "") {
    //   setToasterValue("Please submit revenue values");
    //   setToaster(true);
    //   return;
    // }
    // if (!EBITDA2019 || !EBITDA2019 || !EBITDA2020) {
    //   setToasterValue("Please submit ebitda values");
    //   setToaster(true);
    //   return;
    // }
    if (
      !companyName ||
      !url ||
      !industry ||
      !productDescription ||
      !contactName ||
      !contactEmail
    ) {
      setToasterValue("Please fill out all fields");
      setToaster(true);
      return;
    }
    let revenue = {
      2018: revenue2018,
      2019: revenue2019,
      2020: revenue2020,
    };

    let EBITDA = {
      2018: EBITDA2018,
      2019: EBITDA2019,
      2020: EBITDA2020,
    };

    let location = {
      state: locationState,
      address: locationAddress,
      address2: locationAddress2,
      city: locationCity,
      zip: locationZip
    };

    let basic = {
      companyName: companyName,
      url: url,
      industry: industry,
      productDescription: productDescription,
      contactName: contactName,
      contactEmail: contactEmail,
    };
    const formData = new FormData();
    file.forEach((item) => {
      formData.append(item.name, item);
    });
    formData.append("revenue", JSON.stringify(revenue));
    formData.append("ebitda", JSON.stringify(EBITDA));
    formData.append("location", JSON.stringify(location));
    formData.append("basic", JSON.stringify(basic));

    //console.log(formData);

    uploadDeal(formData);
    resetFields();
    setSuccessToaster(true);
  };

  return (
    <div align="center">
      <div align="left">
        <Link to="/upload">
        <IconButton
        >
          <ArrowBackIcon />
        </IconButton>
        </Link>
      </div>
      <Card>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{
              backgroundColor:
                companyName !== "" &&
                contactName !== "" &&
                contactEmail !== "" &&
                industry !== "" &&
                productDescription !== ""
                  ? "#bbdb76"
                  : "white",
            }}
          >
            <Typography className={classes.heading} align="left">
              Basic Company Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: "grid" }}>
            <TextField
              style={{
                marginTop: 20,
                marginLeft: 12,
                marginRight: 12,
                width: "50%",
              }}
              helperText="Company Name"
              variant="outlined"
              onChange={(event) => {
                setCompanyName(event.target.value);
              }}
              value={companyName}
            />
                        <TextField
              style={{
                marginTop: 20,
                marginLeft: 12,
                marginRight: 12,
                width: "50%",
              }}
              helperText="Website URL"
              variant="outlined"
              onChange={(event) => {
                setURL(event.target.value);
              }}
              value={url}
            />
            <TextField
              multiline
              rowsMax={4}
              rows={3}
              style={{
                marginTop: 20,
                marginLeft: 12,
                marginRight: 12,
                width: "50%",
              }}
              helperText="Product Description"
              variant="outlined"
              onChange={(event) => {
                setProductDescription(event.target.value);
              }}
              value={productDescription}
            />
            <div style={{ display: "flex" }}>
              <TextField
                style={{ marginTop: 20, marginLeft: 12, width: 300 }}
                helperText="Contact Name (First and Last)"
                variant="outlined"
                onChange={(event) => {
                  setContactName(event.target.value);
                }}
                value={contactName}
              />
              <TextField
                style={{ marginTop: 20, marginLeft: 12, width: 300 }}
                helperText="Contact Email"
                variant="outlined"
                onChange={(event) => {
                  setContactEmail(event.target.value);
                }}
                value={contactEmail}
              />
              <br />
              <FormControl>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  value={industry}
                  onChange={handleIndustry}
                  style={{
                    marginTop: 20,
                    marginLeft: 12,
                    width: 300,
                    height: 55,
                  }}
                >
                  {industry_array.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  style={{ marginLeft: "10%", marginRight: "50%" }}
                >
                  Industry
                </FormHelperText>
              </FormControl>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            style={{
              backgroundColor:
                locationState && locationAddress && locationCity && locationZip ? "#bbdb76" : "white",
            }}
          >
            <Typography className={classes.heading} align="left">
              Location Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <TextField multiline
                rows={2}
                style={{ marginTop: 20, marginLeft: 12 }}
                helperText="Street Address"
                variant="outlined"
                onChange={(event) => {
                  setLocationAddress(event.target.value);
                }}
                value={locationAddress}
              />
              <TextField
              multiline
              rows={2}
                style={{ marginTop: 20, marginLeft: 12 }}
                helperText="Street Address 2 (optional)"
                variant="outlined"
                onChange={(event) => {
                  setLocationAddress2(event.target.value);
                }}
                value={locationAddress2}
              />
              <TextField
                style={{ marginTop: 20, marginLeft: 12 }}
                helperText="City"
                variant="outlined"
                onChange={(event) => {
                  setLocationCity(event.target.value);
                }}
                value={locationCity}
              />
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                value={locationState}
                onChange={(event) => {
                  setLocationState(event.target.value);
                }}
                style={{
                  marginTop: 20,
                  marginLeft: 12,
                  width: 300,
                  height: 55,
                }}
              >
                {state_array.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText style={{ marginLeft: "10%", marginRight: "50%" }}>
                State
              </FormHelperText>
              <TextField
                style={{ marginTop: 20, marginLeft: 12 }}
                helperText="Zip Code"
                variant="outlined"
                onChange={(event) => {
                  setLocationZip(event.target.value);
                }}
                value={locationZip}
              />
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
            style={{
              backgroundColor:
                EBITDA2018 &&
                EBITDA2019 &&
                EBITDA2020 &&
                revenue2018 &&
                revenue2019 &&
                revenue2020
                  ? "#bbdb76"
                  : "white",
            }}
          >
            <Typography className={classes.heading} align="left">
              Financial Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: "block" }}>
            <TextField
              style={{ marginTop: 2, marginLeft: 20 }}
              onChange={(event) => {
                setRevenue2018(event.target.value);
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              error={revenueError[0]}
              value={revenue2018}
              helperText="2018 revenue"
              variant="outlined"
            />
            <TextField
              style={{ marginTop: 2, marginLeft: 20 }}
              onChange={(event) => {
                setRevenue2019(event.target.value);
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              value={revenue2019}
              helperText="2019 revenue"
              variant="outlined"
            />
            <TextField
              style={{ marginTop: 2, marginLeft: 20 }}
              onChange={(event) => {
                setRevenue2020(event.target.value);
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              error={revenueError[0]}
              value={revenue2020}
              helperText="2020 revenue"
              variant="outlined"
            />
            <br />
            <TextField
              style={{ marginTop: 2, marginLeft: 20 }}
              onChange={(event) => {
                setEBITDA2018(event.target.value);
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              error={revenueError[0]}
              value={EBITDA2018}
              helperText="2018 EBITDA"
              variant="outlined"
            />
            <TextField
              style={{ marginTop: 2, marginLeft: 20 }}
              onChange={(event) => {
                setEBITDA2019(event.target.value);
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              error={revenueError[0]}
              value={EBITDA2019}
              helperText="2019 EBITDA"
              variant="outlined"
            />
            <TextField
              style={{ marginTop: 2, marginLeft: 20 }}
              onChange={(event) => {
                setEBITDA2020(event.target.value);
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              error={revenueError[0]}
              value={EBITDA2020}
              helperText="2020 EBITDA"
              variant="outlined"
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{ backgroundColor: file && checked1 && checked2 && checked3 && checked4 ? "#bbdb76" : "white" }}
          >
            File Upload
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ marginTop: 10 }}>
              <input
                id="file-upload"
                type="file"
                onChange={(event) => handleFileUpload(event)}
                style={{ display: "none" }}
              />
              <label
                for="file-upload"
                style={{
                  borderRadius: 4,
                  border: ".5px solid grey",
                  padding: 10,
                  cursor: "pointer",
                }}
              >
                Choose File
              </label>
              <div style={{ marginTop: 8 }}>
                {file &&
                <>
                {
                  file.map((item, index) => (
                    <Typography style={{ fontSize: 12 }}>
                      {fileIcon[index] && (
                        <FontAwesomeIcon icon={fileIcon[index]} />
                      )}
                      {" " + item.name + "  "}
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        icon={faMinusCircle}
                        onClick={() => {
                          handleFileDelete(index);
                        }}
                      />
                    </Typography>
                  ))
                  }
                  <FormGroup align="left">
                   <FormControlLabel
                  control={
                    <Checkbox
                    checked={checked4}
                    onChange={() => {setChecked4(!checked4)}}
                    name="checkedIV"
                    color="primary"
                    />
                  }
                  label="I certify that I have been granted permission to share the information provided above."
                />
                  <FormControlLabel
                  control={
                    <Checkbox
                    checked={checked1}
                    onChange={() => {setChecked1(!checked1)}}
                    name="checkedI"
                    color="primary"
                    />
                  }
                  label="I certify that all information provided above is true to the best of my knowledge."
                />
                  <FormControlLabel
                  control={
                    <Checkbox
                    checked={checked2}
                    onChange={() => {setChecked2(!checked2)}}
                    name="checkedII"
                    color="primary"
                    />
                  }
                  label="I agree to all terms and conditions outlined in the Velladore Terms of Use."
                />
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={checked3}
                    onChange={() => {setChecked3(!checked3)}}
                    name="checkedIII"
                    color="primary"
                    style={{verticalAlign: 'top'}}
                    />
                  }

                  style={{textAlign: 'bottom'}}
                  label={<div>I understand that Velladore will review this submission and assign a finder’s fee rate commensurate with the perceived quality and depth of information provided. If a firm closes a deal due to the information provided, this rate will be applied to the final purchase price, and the fee will be split evenly between your firm and Velladore.</div>}
                />
                </FormGroup>
                </>
                  }
                {(!file || file.length === 0) && (
                  <Typography style={{ fontSize: 12 }}>
                    No file chosen
                  </Typography>
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <div style={{ marginBottom: "30px" }}></div>
        <Snackbar open={toaster} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="warning">
            {toasterValue}
          </Alert>
        </Snackbar>
        <Snackbar
          open={successToaster}
          autoHideDuration={6000}
          onClose={() => {
            setSuccessToaster(false);
          }}
        >
          <Alert
            onClose={() => {
              setSuccessToaster(false);
            }}
            severity="success"
          >
            Thanks -- your submission has been received!
          </Alert>
        </Snackbar>
        <div style={{ marginBottom: "30px" }}>
          {/*<DropzoneArea onChange={event => handleFileUpload(event)} onDelete={handleFileDelete}/> */}
          <br />
          <Button
            variant="outlined"
            disabled={
              revenue2018 === "" ||
              revenue2019 === "" ||
              revenue2020 === "" ||
              EBITDA2018 === "" ||
              EBITDA2019 === "" ||
              EBITDA2020 === "" ||
              !companyName ||
              !locationState ||
              !locationAddress ||
              !locationZip ||
              !locationCity ||
              !industry ||
              !productDescription ||
              !contactEmail ||
              !contactName ||
              !file ||
              !checked1 ||
              !checked2 ||
              !checked3 ||
              !checked4
            }
            onClick={fireUploads}
            style={{ textTransform: "none" }}
          >
            Submit Diligence
          </Button>
        </div>
      </Card>
    </div>
  );
};

export { Upload, extractFileType };
