import React from "react";
// import "../style/ndadocument.css";
import SignatureCanvas from "react-signature-canvas";
import { Dialog, DialogContent, DialogActions, Paper } from "@material-ui/core";
import { getDealsById } from "../API";

const today = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;

  return today;
};

const NDAdocument = (props) => {
  const [canvas, setCanvas] = React.useState(false);
  let refer = React.createRef();
  let display = React.createRef();
  const [providerName, setProviderName] = React.useState(null);
  const [receiverName, setReceiverName] = React.useState(props.user.name);
  const [providerFirm, setProviderFirm] = React.useState();
  const [receiverFirm, setReceiverFirm] = React.useState(props.user.firm);
  const [receiverState, setReceiverState] = React.useState(props.user.state);

  //   React.useEffect(() => {
  //     if (!props.dealid) {
  //         return;
  //     }
  //     getDealsById([props.dealid])
  //     .then(res => {
  //         console.log('deal to go on')
  //         console.log(res);
  //     })
  //     .catch(err => {
  //         console.log(err);
  //     })
  //   }, [props.dealid])

  return (
    <Paper elevation={2} style={{ width: "60%" }} align="center">
      <head>
        <meta
          httpEquiv="Content-Type"
          content="text/html; charset=windows-1252"
        />
        <meta name="ProgId" content="Word.Document" />
        <meta name="Generator" content="Microsoft Word 15" />
        <meta name="Originator" content="Microsoft Word 15" />
        <link rel="File-List" href="Finder's%20Fee_v2_files/filelist.xml" />
      </head>

      <body lang="EN-US" style={{ tabInterval: ".5in", padding: "1in" }}>
        <div className="WordSection1">
          <p
            className="MsoNormal"
            align="center"
            style={{ textAlign: "center" }}
          >
            <b style={{ msoBidiFontWeight: "normal" }}>
              <i style={{ msoBidiFontStyle: "normal" }}>
                <span
                  style={{
                    fontSize: "14.0pt",
                    msoBidiFontSize: "12.0pt",
                    fontFamily: "Times",
                    msoFareastFontFamily: "Times",
                  }}
                >
                  FINDER'S FEE AGREEMENT
                </span>
              </i>
            </b>{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal" style={{ textIndent: "27.0pt" }}>
            In consideration of a Finder's Fee (as defined below), payable only
            upon the successful consummation of the services (hereinafter
            &quot;Discovery&quot;),{" "}
            <b>
              <u>
                <span style={{ color: "red" }}>PROVIDING FIRM</span>
              </u>
            </b>
            <span style={{ color: "red" }}> </span>(&quot;Finder&quot;) of{" "}
            <b>
              <u>
                <span style={{ color: "red" }}>PROVIDING FIRM ADDRESS</span>
              </u>
            </b>{" "}
            agrees to assist{" "}
            <b>
              <u>
                <span style={{ color: "red" }}>{receiverFirm}</span>
              </u>
            </b>{" "}
            (&quot;Client&quot;) of{" "}
            <b>
              <u>
                <span style={{ color: "red" }}>RECEIVING FIRM ADDRESS</span>
              </u>
            </b>{" "}
            in the Discovery. For purposes of this agreement
            (&quot;Agreement&quot;):{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal">
            -<span style={{ msoTabCount: "normal" }}> </span>
            <b>
              <u>
                <span style={{ color: "red" }}>PROVIDING FIRM</span>
              </u>
            </b>{" "}
            will provide accurate and relevant deal and company information,
            such that{" "}
            <b>
              <u>
                <span style={{ color: "red" }}>{receiverFirm}</span>
              </u>
            </b>
            <span style={{ color: "red" }}> </span>can progress with internal
            diligence processes{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal">
            <b style={{ msoBidiFontWeight: "normal" }}>1. Discovery.</b>{" "}
          </p>

          <p className="MsoNormal">
            Finder's &quot;Discovery&quot; of clients shall occur only if the
            clients are identified and introduced by Finder and acknowledged by
            Client in writing during the Retention Period (as defined below).
            Finder shall not be deemed to have discovered any clients who
            contacted or were contacted by Client on or before the Effective
            Date (as defined below), or any clients who hereafter contact or are
            contacted by Client after the Effective Date before being identified
            and introduced in writing by Finder to Client (if at all)
            (collectively, &quot;Independent Contacts&quot;).{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal">
            <b style={{ msoBidiFontWeight: "normal" }}>
              2. Business Transaction.
            </b>{" "}
          </p>

          <p className="MsoNormal">
            For purposes of this Agreement, the &quot;Business Transaction&quot;
            shall be defined as{" "}
            <u>
              <span style={{ color: "black" }}>closing of the deal</span>
            </u>
            <span style={{ color: "black" }}> </span>
            by Client on the terms and conditions acceptable to Client. The
            Client reserves all rights, in its sole and absolute discretion, and
            without any obligation to pay a Finder's Fee or any other sum to
            Finder, to withdraw from negotiations concerning any proposed
            Business Transaction, and/or accept or reject any finding by the
            Finder, or all or part of any proposed Business Transaction, at any
            time, with or without cause. As used herein,
            &quot;consummation&quot; of the Business Transaction shall not be
            deemed to have occurred unless and until, among other things:{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal">
            -<span style={{ msoTabCount: 1 }}> </span>
            <u>
              <span sstyle={{ color: "#0070C0" }}>
                Deal is officially closed between Client and company
              </span>
            </u>
          </p>

          <p className="MsoNormal">
            -<span style={{ msoTabCount: "normal" }}> </span>
            <u>
              <span style={{ color: "black" }}>
                Deal is closed within 2 years of the signing of this agreement
              </span>
            </u>
          </p>

          <p className="MsoNormal">
            -<span style={{ msoTabCount: "normal" }}> </span>
            <u>
              <span style={{ color: "black" }}>
                Deal is closed primarily because of the information provided via
                the Velladore platform
              </span>
            </u>
          </p>

          <p className="MsoNormal">&nbsp;</p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal">
            <b style={{ msoBidiFontWeight: "normal" }}>
              3. Term and Retention Period.
            </b>{" "}
          </p>

          <p className="MsoNormal">
            The non-exclusive right granted by this Agreement shall commence on{" "}
            <b>
              <u>
                <span style={{ color: "red" }}>{today()}</span>
              </u>
            </b>{" "}
            (&quot;Effective Date&quot;) and the &quot;Retention Period&quot;
            shall continue for 2 years at which point either party may indicate
            to the other, in writing, its prospective intention to terminate
            this Agreement. Finder shall be entitled to its Finder's Fee during
            the Retention Period, or, in the event a Business Transaction is
            consummated after termination of the Retention Period, and under the
            terms of this Agreement, within 30 days of the consummation of the
            Business Transaction.
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal">
            <b style={{ msoBidiFontWeight: "normal" }}>4</b>.{" "}
            <b style={{ msoBidiFontWeight: "normal" }}>Exclusivity.</b>{" "}
          </p>

          <p className="MsoNormal">
            Finder's engagement under this Agreement is non-exclusive, and
            Client shall have the continuing right to deal with, and consummate
            Business Transactions with, other clients not discovered by Finder,
            either directly or through other brokers, agents, finders or other
            representatives, without any obligation to pay Finder a Finder's Fee
            or any other sum.{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal">
            <b style={{ msoBidiFontWeight: "normal" }}>5</b>.{" "}
            <b style={{ msoBidiFontWeight: "normal" }}>Non-Circumvention.</b>{" "}
          </p>

          <p className="MsoNormal">
            During the term of this Agreement, Client will not attempt to do
            business with, or otherwise solicit any clients found or otherwise
            referred by Finder to Client for the purpose of circumventing, the
            result of which shall be to prevent the Finder from realizing or
            recognizing a commission or Finder's Fee. If such circumvention
            shall occur the Finder shall be entitled to any commissions due
            pursuant to this Agreement or Finder's Fee relating to such
            transaction.{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal">
            <b style={{ msoBidiFontWeight: "normal" }}>6</b>.{" "}
            <b style={{ msoBidiFontWeight: "normal" }}>Eligibility.</b>{" "}
          </p>

          <p className="MsoNormal">
            Finder shall be entitled to the Finder's Fee{" "}
            <span className="GramE">in the event that</span> during the
            Retention Period (<span className="SpellE">i</span>) the Business
            Transaction is consummated or materialized, and (ii) Finder
            discovered the clients to assist Client during the Retention Period.{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            <b style={{ msoBidiFontWeight: "normal" }}>7. Date of Payment.</b>{" "}
          </p>

          <p className="MsoNormal">
            The Finder's Fee shall become due and payable by the Client
            immediately upon consummation of the Business Transaction. Client
            shall have no obligation to pay Finder any Finder's Fee or other
            amount if the Business Transaction in question fails to close for
            any reason, including without limitation, as a result of an actual
            or purported breach or default by Client.{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            <b style={{ msoBidiFontWeight: "normal" }}>
              8. Calculation of Finder's Fee.
            </b>{" "}
          </p>

          <p className="MsoNormal">
            For purposes of this Agreement, the Finder's Fee shall be calculated
            at{" "}
            <b>
              <u>
                <span style={{ color: "red" }}>RATE</span>
              </u>
            </b>{" "}
            of the Business Transaction consideration. The Finder's Fee shall be
            calculated on a pro-rata basis against{" "}
            <span className="GramE">any and all</span> consideration contributed
            (discovered) by Client and their exclusive subject matter not
            identified and introduced in writing by Finder.{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            <b style={{ msoBidiFontWeight: "normal" }}>
              9. Independent Contractor.
            </b>{" "}
          </p>

          <p className="MsoNormal">
            It is understood that this Agreement provides for the rendering of
            services by Finder as a finder only, as described herein, and does
            not include the rendering of any other services, including due
            diligence services. Finder acknowledges that it is an independent
            contractor and shall not be deemed to be Client's agent for any
            purposes whatsoever. Finder shall have no right or authority to
            assume or create any obligation or liability, expressed or implied,
            for or on behalf of Client, or to otherwise bind Client in any
            manner whatsoever.{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            <b style={{ msoBidiFontWeight: "normal" }}>10. Hold Harmless.</b>{" "}
          </p>

          <p className="MsoNormal">
            If Finder shall become entitled to a Finder's Fee under this
            Agreement, Finder hereby agrees to indemnify, protect, defend and
            hold harmless Client and its affiliates from and against any and all
            claims, actions, losses, liabilities, damages, liens, costs or
            expenses (including, without limitation, reasonable attorneys' fees
            and costs), however described, arising from any claim by any other
            person or entity for brokerage or finder's fees, commissions, or
            similar compensation in connection with any Business Transaction
            involving a Prospective Investor discovered by Finder.{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            <b style={{ msoBidiFontWeight: "normal" }}>11. Sharing of Fees.</b>{" "}
          </p>

          <p className="MsoNormal">
            The Finder is specifically authorized to engage the services of any
            sub-agents and to share fees and commissions with other agents,
            provided that full disclosure of any fee or commission sharing is
            made to all parties.{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            <b style={{ msoBidiFontWeight: "normal" }}>12. Governing Law.</b>{" "}
          </p>

          <p className="MsoNormal">
            This Agreement shall be made in and shall be governed by and
            interpreted in accordance with the laws of the State of{" "}
            <b>
              <u>
                <span style={{ color: "red" }}>{receiverState}</span>
              </u>
            </b>
            .{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            <b style={{ msoBidiFontWeight: "normal" }}>13. Confidentiality. </b>
          </p>

          <p className="MsoNormal">Finder agrees to: </p>

          <p className="MsoNormal">
            (<span className="SpellE">i</span>)
            <span style={{ msoTabCount: "normal" }}> </span>
            hold all confidential, non-public information received from Client
            or its affiliates, or their respective representatives, strictly
            confidential,{" "}
          </p>

          <p className="MsoNormal">
            (ii)<span style={{ msoTabCount: "normal" }}> </span>use or release
            such information only in the performance of this Agreement, and not
            use or release or permit the use or release of any of such
            information for any other purposes, and{" "}
          </p>

          <p className="MsoNormal">
            (iii)<span style={{ msoTabCount: "normal" }}> </span>return to
            Client all such information in whatever format (and all copies
            thereof) at the conclusion of this engagement.{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            <b style={{ msoBidiFontWeight: "normal" }}>14. Attorneys' Fees. </b>
          </p>

          <p className="MsoNormal">
            If legal action is brought to enforce or interpret this Agreement or
            any of its provisions, the prevailing party shall be entitled to
            reasonable attorneys' fees in addition to any other recovery to
            which such party may be entitled.{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            <b style={{ msoBidiFontWeight: "normal" }}>15. Termination.</b>{" "}
          </p>

          <p className="MsoNormal">
            This Agreement may be so terminated by either party at any time,
            with or without cause. This Agreement may be terminated by either
            party by the giving of written notice of termination to the other.{" "}
            <span className="GramE">In the event that</span> the Client sends a
            notice of termination after the Finder has incurred specific, direct
            expenses in connection with this Agreement, the Client shall
            reimburse the Finder for the amount of the expenses at the time of
            the termination.{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            <b style={{ msoBidiFontWeight: "normal" }}>16. Entire Agreement.</b>{" "}
          </p>

          <p className="MsoNormal">
            This Agreement contains <span className="GramE">all of</span> the
            agreements of the Parties with respect to any matter covered or
            mentioned in this Agreement and no prior agreements shall be
            effective for any purpose.{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            <b style={{ msoBidiFontWeight: "normal" }}>17. No Waiver.</b>{" "}
          </p>

          <p className="MsoNormal">
            Failure or delay of Client to declare any breach or default
            immediately upon occurrence shall not waive such breach or default
            by the Finder.{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            <b style={{ msoBidiFontWeight: "normal" }}>18. Notices.</b>{" "}
          </p>

          <p className="MsoNormal">
            Any notices required to be given by the Parties shall be delivered
            at the addresses set forth in the beginning of the Agreement. Any
            notices may be delivered personally to the addressee of the notice
            or may be deposited in the United States mail, postage prepaid, to
            the address of the parties.{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p
            className="MsoNormal"
            style={{ textIndent: "27.0pt", pageBreakAfter: "avoid" }}
          >
            <b style={{ msoBidiFontWeight: "normal" }}>IN WITNESS WHEREOF</b>,
            this Finder's Fee Agreement by and between{" "}
            <b>
              <u>
                <span style={{ color: "red" }}>
                  NAME OF PROVIDER REPRESENTATIVE
                </span>
              </u>
            </b>
            ,{" "}
            <b>
              <u>
                <span style={{ color: "red" }}>
                  TITLE OF PROVIDER REPRESENTATIVE
                </span>
              </u>
            </b>
            , on behalf of{" "}
            <b>
              <u>
                <span style={{ color: "red" }}>PROVIDER</span>
              </u>
            </b>
            and{" "}
            <b>
              <span style={{ color: "red" }}>{receiverName}</span>
            </b>
            ,
            <b>
              <u>
                <span style={{ color: "red" }}>
                  TITLE OF RECEIVER REPRESENTATIVE
                </span>
              </u>
            </b>
            , on behalf of{" "}
            <b>
              <u>
                <span style={{ color: "red" }}>{receiverFirm}</span>
              </u>
            </b>{" "}
            has been executed and delivered in the manner prescribed by law as
            of the date first written above.{" "}
          </p>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            &nbsp;{" "}
          </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            CLIENT:{" "}
          </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            <b>
              <span style={{ color: "red" }}>RECEIVER: {receiverFirm}</span>
            </b>
          </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            &nbsp;{" "}
          </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            &nbsp;{" "}
          </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            &nbsp;{" "}
          </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            <div style={{ display: "flex", position: "relative" }} align="left">
              {" "}
              <div
                style={{
                  borderBottom: "1px solid black",
                  width: 800,
                  height: 155,
                  paddingBottom: 5,
                }}
              >
                <SignatureCanvas
                  align="left"
                  ref={(ref) => {
                    display = ref;
                  }}
                  canvasProps={{
                    width: 500,
                    height: 150,
                    className: "sigCanvas",
                  }}
                />
              </div>
              <div style={{ position: "absolute", bottom: 5, right: 0 }}>
                <strong>Date</strong> {today()}
              </div>{" "}
            </div>
          </p>

          <p className="MsoNormal" style={{ pageBreakAfter: "avoid" }}>
            <b>
              <span style={{ color: "red" }}>{receiverName}</span>
            </b>
            ,{" "}
            <b>
              <u>
                <span style={{ color: "red" }}>
                  TITLE OF RECEIVER REPRESENTATIVE
                </span>
              </u>
            </b>
          </p>

          <button
        style={{ marginBottom: 8 }}
        onClick={() => {
          setCanvas(true);
        }}
      >
        {" "}
        sign{" "}
      </button>

          <p className="MsoNormal">&nbsp; </p>

          <p className="MsoNormal">&nbsp;</p>
        </div>
      </body>


      <Dialog
        width="md"
        maxWidth={true}
        open={canvas}
        onClose={() => {
          setCanvas(false);
        }}
      >
        <DialogContent>
          <SignatureCanvas
            ref={(ref) => {
              refer = ref;
            }}
            penColor="black"
            canvasProps={{ width: 500, height: 150, className: "sigCanvas" }}
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              refer.clear();
              display.clear();
            }}
          >
            clear
          </button>
          <button
            onClick={() => {
              display.fromData(refer.toData());
              setCanvas(false);
              props.setSig(refer.toData());
            }}
          >
            done
          </button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default NDAdocument;
