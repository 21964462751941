import React from "react";
import explore from "../static_pages/explore.png";
import account from "../static_pages/account.png";
import { Grid, Paper } from "@material-ui/core";
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faThumbsDown,
  faTrash,
  faSignal,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import '../style/Process.css'
import icon_cycle from "../static_pages/cycle_vell.png";

export default function Process() {
  return (
    <div style={{ paddingBottom: 0, paddingRight: 0, paddingLeft: 0, paddingTop: 0, backgroundColor: '#1E1E44', border: 'none' }} elevation={0} className="container cf">
      <Grid container>
        {/* <Grid item xs={12} md={6}>
            <img
              src={explore}
              style={{
                objectFit: "cover",
                width: "100%",
                border: "1px solid black",
                marginLeft: 10,
              }}
              alt="siteIntro"
            />
          </Grid> */}
        <Grid item xs={12} lg={6} align="left" style={{backgroundColor: 'white', padding: 22, paddingTop: 70, paddingBottom: 100}}>
          <h2 align="center" style={{color: '#1E1E44', fontSize: 54}}>The <br/> Problem</h2>
          <div
            className="animation-element slide-left testimonial"
            style={{ display: "flex", fontSize: 40, color: "#1E1E44", marginBottom: 30, height: 160 }}
          >
            <FAIcon icon={faClock} style={{ fontSize: 60, marginRight: 30 }} />
            The direct sourcing process is time consuming and difficult.
          </div>
          <div
            className="animation-element slide-left testimonial"
            style={{ display: "flex", fontSize: 40, color: "#1E1E44", marginBottom: 30, height: 160 }}
          >
            <FAIcon icon={faThumbsDown} style={{ fontSize: 60, marginRight: 30 }} />
            After the process concludes and a decision of pass is reached...
          </div>
          <div
            className="animation-element slide-left testimonial"
            style={{ display: "flex", fontSize: 40, color: "#1E1E44", height: 160  }}
          >
            <FAIcon icon={faTrash} style={{ fontSize: 60, marginRight: 30}} />
            ...deal information is discarded or 
            archived, never to be used again.
          </div>

          {/* <ol>
              <li>Buy-side expresses their firm's investment criteria.</li>
              <li>
                Sell-side adds deal details to Velladore site via a
                fully-encrypted data pipeline.
              </li>
              <li>
                Velladore assigns a finder's fee rate to the sell side's deal.
              </li>
              <li>
                Interested buy-side parties obtain access to custom-tailored
                deals.
              </li>
              <li>
                Buy-side receives access to company-specific and industry-wide
                data directly on the Velladore platform.
              </li>
            </ol> */}
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          align="left"
          style={{color: "white", padding: 22, paddingTop: 70}}
        >
          <h2 align="center" style={{fontSize: 54}} >The Velladore <div style={{color: '#7d3a98'}}> Solution</div></h2>
          <div
            className="animation-element slide-left testimonial"
            style={{ display: "flex", fontSize: 40, marginBottom: 30, height: 160 }}
          >
            <img src={icon_cycle} style={{ height: 70, marginRight: 30 }} />
            Velladore aggregates this discarded information from multiple
            firms...
          </div>
          <div
            className="animation-element slide-left testimonial"
            style={{ display: "flex", fontSize: 40, marginBottom: 30, height: 160 }}
          >
            <FAIcon icon={faHandshake} style={{ fontSize: 60, marginRight: 30 }} />
            ...and matches it with interested private equity firms across
            the lower middle market...{" "}
          </div>
          <div
            className="animation-element slide-left testimonial"
            style={{ display: "flex", fontSize: 40, marginBottom: 30, height: 160 }}
          >
            <FAIcon icon={faSignal} style={{ fontSize: 50, marginRight: 30}} />
            ...creating tangible value for both the provider and receiver of
            the information.
          </div>
        </Grid>
        {/* <Grid item xs={12} md={6} style={{ marginTop: 25 }}>
          <img
            src={account}
            style={{
              objectFit: "cover",
              width: "100%",
              border: "1px solid black",
              marginLeft: 10,
            }}
            alt="site intro2"
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ marginTop: 25 }}>
          <ul>
            <li>Access more dealflow.</li>
            <li>
              Build a profile to ensure that you are aware of relevant deals and
              feed our internal deal engine.
            </li>
            <li>
              Build a landscape of the industry and company in question before
              diving into the diligence.
            </li>
            <li>See company financial trajectory.</li>
          </ul>
        </Grid> */}
      </Grid>
    </div>
  );
}
