import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import '../../style/Download.css';
import Checkbox from '@material-ui/core/Checkbox';
import { Deals } from './Deals2';
import Toolbar from './DealsToolbar';
import NumberFormatCustom from './Helpers/DollarFormat';
import { getDeals } from '../../API';
import { propTypes } from 'react-bootstrap/esm/Image';


const Download  = (props) => {

    const [dealsSearched, setDealsSearched] = React.useState(null);

    const query_deals = (revenue, ebitda, industry, location, favorited) => {
        console.log('favorited from query_deals: ', favorited);
        getDeals(revenue, ebitda, industry, location, favorited)
        .then(res => {
            console.log(res.data);
            if (res.data.length === 0) {
                alert('search parameters too narrow. Please widen parameters');
            }
            else {
                setDealsSearched(res.data);
                console.log('success');
            }
        })
        .catch(err => {
            console.error(err);
        })
    }



    // const handleLocation = (newVal) => {
    //     setLocation(newVal.target.value)
    // }

    // const handleIndustry = (newVal) => {
    //     setIndustry(newVal.target.value);
    // }

    return (
        <>
             <Card align="center">
                 {
                     props.user &&
                     <Toolbar deal_search={query_deals} search_terms={props.user}/>
                 }
                   <Deals paginator={props.paginator} handlePaginator={props.handlePaginator} deals={dealsSearched} user={props.user}/>
                 {/*
                    <TextField
                        style={{marginRight: 30, marginTop: 40}}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                        variant="outlined"
                        helperText="min revenue"
                        onChange={event => {setMinRevenue(event.target.value)}}
                        value={minRevenue}
                    />
                    <TextField
                        style={{marginTop: 40}}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                        variant="outlined"
                        helperText="max revenue"
                        onChange={event => {setMaxRevenue(event.target.value)}}
                        value={maxRevenue}
                    />
            <br/>
                <TextField
                    style={{marginRight: 30, marginTop: 20}}
                    InputProps={{
                        inputComponent: NumberFormatCustom
                    }}
                    variant="outlined"
                    helperText="min EBITDA"
                    onChange={event => {setMinEBITDA(event.target.value)}}
                    value={minEBITDA}
                />
                <TextField
                    style={{marginTop: 20}}
                    InputProps={{
                        inputComponent: NumberFormatCustom
                    }}
                    variant="outlined"
                    className="number_box"
                    onChange={event => {setMaxEBITDA(event.target.value)}}
                    value={maxEBITDA}
                />
            <InputLabel id="industry_label">Industry</InputLabel>
                <Select
                variant="outlined"
                labelId="industry_label"
                id="demo-simple-select-helper"
                value={industry}
                onChange={event => {handleIndustry(event)}}
                style={{marginBottom: '25px', minWidth: 160}}
                >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                <MenuItem value={'Manufacturing'}>Manufacturing</MenuItem>
                <MenuItem value={'Agriculture'}>Agriculture</MenuItem>
                <MenuItem value={'Tech'}>Technology</MenuItem>
                </Select>
                    <br/>
                    <InputLabel id="location_label">Location</InputLabel>
                <Select
                variant="outlined"
                labelId="location_label"
                id="demo-simple-select-helper"
                value={location}
                onChange={event => {handleLocation(event)}}
                style={{marginBottom: '25px', minWidth: 160}}
                >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                <MenuItem value={'East'}>East Coast</MenuItem>
                <MenuItem value={'West'}>West Coast</MenuItem>
                <MenuItem value={'Midwest'}>Midwest</MenuItem>
                <MenuItem value={'South'}>South</MenuItem>
                <MenuItem value={'Colorado'}>Best state (Colorado)</MenuItem>
                </Select>
                    <br/>*/}
                    <br/>

        {/* <Button
            style={{marginTop: '30px', marginBottom: '30px'}}
            color="primary"
            variant="contained"
        >
            Search
        </Button>   */}
            </Card>

</>

    )
}

export default Download;

