import Typography from '@material-ui/core/Typography';
import React from 'react';
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { AccordionSummary, FormControl, FormControlLabel, FormHelperText } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Help = (props) => {


    return (
        <div align="center">
            <h1>
                FAQs:
            </h1>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    What is the risk of uploading to Velladore?
                </AccordionSummary>
                <AccordionDetails>
                    There's virtually no risk.
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>} >
                    How many firms are using the site?
                </AccordionSummary>
                <AccordionDetails>
                    We have about 3000 daily active users and are onboarding about 30 every day.
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    What is the range of finder's fee rates that I may receive?
                </AccordionSummary>
                <AccordionDetails>
                    If your deal is downloaded and closed upon, you will receive between 1% and 4% of the closing amount. This is determined by the level of detail provided in the diligence.
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    Where are you located?
                </AccordionSummary>
                <AccordionDetails>
                    Our headquarters are in Boulder, Colorado, though we have satellite offices in Nashville and New York City.
                </AccordionDetails>
                </Accordion>
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    I'm a company looking to sell my business. Do you have services for me?
                </AccordionSummary>
                <AccordionDetails>
                    Yes, send us a message below and we will connect you with one of our associates about getting in the Velladore network.
                </AccordionDetails>
                </Accordion>
      </div>
    )
}

export default Help;

