import Typography from "@material-ui/core/Typography";
import React, {useEffect} from "react";
import { Grid, IconButton, Badge, Fab } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Button from "@material-ui/core/Button";
import { PieChart, Pie, Sector, Cell, Bar } from "recharts";
import Deals from "./Deals";
import { getUserDeals } from '../../API';
import Dashboard_Pages from './Dashboard_Pages';
import UploadStatus from './UploadStatus';
import Kanban from './Kanban';
import ModalDraggable from './DraggableModal';
import { Link } from 'react-router-dom';

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const useStyles = makeStyles({
  buttons: {
    minWidth: 300,
    backgroundColor: '#1E1E44',
    color: 'white'
  },
});

const getDeals = () => {

};

const Dashboard = () => {
  const [dealsOpen, setDealsOpen] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [toReview, setToReview] = React.useState(false);
  const [reviewed, setReviewed] = React.useState(false);
  const [deals, setDeals] = React.useState(null);
  const [savedLength, setSavedLength] = React.useState(0);
  const [toReviewLength, setToReviewLength] = React.useState(0);
  const [reviewedLength, setReviewedLength] = React.useState(0);

  const classes = useStyles();

  useEffect(() => {
    getUserDeals()
    .then(ret => {
      console.log(ret)
        const results = ret.data[0];
        setSavedLength(results['downloaded'].length);
        setToReviewLength(results['inReview'].length)
        setReviewedLength(results['reviewed'].length)
        setDeals(results);
    })
    .catch(err => {
        console.log(err);
    })
  }, [] );

  const handleDealDialog = () => {
      setDealsOpen(!dealsOpen);
  }
  const savedDialog = () => {
      setSaved(!saved);
  }
  const toReviewDialog = () => {
    setToReview(!toReview);
}
const reviewedDialog = () => {
    setReviewed(!reviewed);
}

const setSavedNumber = () => {

}

  return (
    <div>
      <Grid container>
        <Grid item xs={10}>
        <Kanban deals={deals}/>
        </Grid>
        <Grid item xs={2} style={{backgroundColor: 'white'}} align="left">
          <Link to="/closeddeal">
          <Button variant='outlined'>
            Deal Closure?
          </Button>
          </Link>
        </Grid>

      </Grid>
        {/* <h2>
              Downloads
          </h2>
      <div style={{ display: "flex", marginTop: 30 }}>
        <div style={{ marginLeft: 30 }}>
          <Badge badgeContent={savedLength} color="secondary">
            <Button
              className={classes.buttons}
              variant="contained"
              color="primary"
              onClick={() => {
                setSaved(true);
              }}
              style={{minHeight : '150px'}}
            >
              Saved Deals
            </Button>
          </Badge>
          <Dashboard_Pages open={saved} savedNumber={setSavedNumber} deals={deals} type={'saved'} handleDealDialog={savedDialog} title={'Saved deals'}/>
          <Dashboard_Pages open={toReview} deals={deals} type={'toReview'} handleDealDialog={toReviewDialog} title={'Deals to Review'}/>
          <Dashboard_Pages open={reviewed} deals={deals} type={'reviewed'} handleDealDialog={reviewedDialog} title={'Reviewed Deals'}/>
        </div>
        <div style={{ marginLeft: 30 }}>
          <Badge badgeContent={toReviewLength} color="secondary">
            <Button
              className={classes.buttons}
              variant="contained"
              color="primary"
              style={{minHeight : '150px'}}
              onClick={() => {
                setToReview(true);
              }}
            >
              Deals to Review
            </Button>
          </Badge>
        </div>
        <div style={{ marginLeft: 30 }}>
          <Badge badgeContent={reviewedLength} color="secondary">
            <Button
              className={classes.buttons}
              variant="contained"
              color="primary"
              style={{minHeight : '150px'}}
              onClick={() => {
                setReviewed(true);
              }}
            >
              Reviewed Deals
            </Button>
          </Badge>
        </div>
      </div>
      <hr/> */}


      
    </div>
  );
};

export default Dashboard;

{
  /* <CardContent>
{dealsOpen && <Deals />}
{!dealsOpen &&
<div>
    <h1> Deal Breakdown </h1>
    <PieChart width={800} height={400}>
        <Pie
            data={data} 
            cx={200} 
            cy={200} 
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={120} 
            fill="#8884d8"
        >
        {
        data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
        }
        </Pie>
    </PieChart>
</div>
} */
}
