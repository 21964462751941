import React from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Grid,
  CardContent,
  CircularProgress
} from "@material-ui/core";
import { getUploaded } from "../../API";
import UploadStatus from "./UploadStatus";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { extractFileType } from "./Upload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VisibilityIcon from "@material-ui/icons/Visibility";
import StarIcon from "@material-ui/icons/Star";
import GetAppIcon from "@material-ui/icons/GetApp";
import UploadPreview from './UploadPreview';
import { Link } from 'react-router-dom';

const rateCalc = (rate) => {
  if (rate === 0) {
    return "NA";
  } else {
    return `${rate}%`;
  }
};

const dateConverter = (date) => {
  let dater = new Date(date);
  let finalizer = dater.getMonth() + 1 + "/";
  finalizer += dater.getDate() + "/";
  finalizer += dater.getFullYear();
  return finalizer;
};

const Selector = (props) => {
  const [deals, setDeals] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [dealId, setDealId] = React.useState(null);
  const [previewIndex, setPreviewIndex] = React.useState(0);
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    setLoading(true);
    if (props.location) {
      setPreviewOpen(props.location.state.previewOpen)
    }
    getUploaded()
      .then((res) => {
        setLoading(false);
        setDeals(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.handlePaginator(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setPreviewOpen(false);
  }

  const handleOpen = (dealId, index) => {
    setPreviewOpen(true);
    setPreviewIndex(index);
    setDealId(dealId);
  }

  // React.useEffect(() => {
  //   if (props.uploadedDeals === null) {
  //     console.log('HERERERER')
  //     props.replenishUploaded();
  //   }
  //   else {
  //     setDeals(props.uploadedDeals)
  //     return;
  //   }
  // }, [props])

  return (
    <>
      <div align="center">
        <Link to="upload/new-deal">
        <Tooltip title="Upload New Deal">
          <Fab
            style={{ marginBottom: 20 }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
        </Link>
        {loading && <> <br/> <CircularProgress /> </>}
      </div>
      <Card align="center">
        <Table>
          <TableHead>
            <TableRow
              style={{
                backgroundColor: "#1E1E44",
                height: 40,
              }}
            >
            {/* <TableCell
                style={{ fontWeight: "bold", color: "white" }}
                align="center"
              >
                Company Name
              </TableCell> */}
              <TableCell
                style={{ fontWeight: "bold", color: "white" }}
                align="left"
              >
                Company Name
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", color: "white" }}
                align="left"
              >
                Upload Date
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", color: "white" }}
                align="left"
              >
                Rate
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", color: "white" }}
                align="center"
              >
                Status
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", color: "white" }}
                align="center"
              >
                Exposure
              </TableCell>
            </TableRow>
          </TableHead>
          {deals &&
            deals
              .slice(page * props.paginator, page * props.paginator + props.paginator)
              .map((item, index) => (
                <TableRow style={{ cursor: 'pointer' }} hover={true} onClick={() => {handleOpen(item['_id'], index)}}>
                      <TableCell
                        align="left"
                        style={{ paddingBottom: 3, paddingTop: 3 }}
                      >
                        {item["companyName"]}
                      </TableCell>
                      <TableCell style={{ paddingBottom: 3, paddingTop: 3 }}>
                        {dateConverter(item["uploadDate"])}
                      </TableCell>
                      <TableCell style={{ paddingBottom: 3, paddingTop: 3 }}>
                        {rateCalc(item["rate"])}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ paddingBottom: 8, paddingTop: 8 }}
                      >
                        <UploadStatus status={item["status"]} />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ paddingBottom: 3, paddingTop: 3 }}
                      >
                        <div>
                          <VisibilityIcon style={{ verticalAlign: "bottom" }} />{" "}
                          80
                        </div>
                        <div>
                          <StarIcon style={{ verticalAlign: "bottom" }} /> 34
                        </div>
                        <div>
                          <GetAppIcon style={{ verticalAlign: "bottom" }} /> 22
                        </div>
                      </TableCell>
                    {/* </AccordionSummary>
                    <AccordionDetails>
                      <Paper>
                        <Grid container spacing={1}>
                          <Grid item xs={3}>
                            <Card>
                              <CardContent>
                                Notes about upload process: <br />
                                Add more documents:
                                <button>add</button>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Paper>
                    </AccordionDetails>
                  </Accordion> */}
                </TableRow>
              ))}
        </Table>
        {
          deals !== null &&
          <TablePagination
          count={deals.length}
          rowsPerPage={props.paginator}
          page={page}
          rowsPerPageOptions={[5, 10, 20]}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        }

      </Card>
      <UploadPreview open={previewOpen} handleClose={handleClose} dealId={dealId} deals={deals} index={previewIndex}/>
    </>
  );
};

export default Selector;
